@font-face {
  font-family: 'Geometria';
  src: local('Geometria-Bold'),
  url('../fonts/geometria/Geometria-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-BoldItalic'),
  url('../fonts/geometria/Geometria-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-ExtraLightItalic'),
  url('../fonts/geometria/Geometria-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-ExtraLight'),
  url('../fonts/geometria/Geometria-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-Light'),
  url('../fonts/geometria/Geometria-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-Heavy'),
  url('../fonts/geometria/Geometria-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria'),
  url('../fonts/geometria/Geometria.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-Italic'),
  url('../fonts/geometria/Geometria-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-ExtraBoldItalic'),
  url('../fonts/geometria/Geometria-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-HeavyItalic'),
  url('../fonts/geometria/Geometria-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-ExtraBold'),
  url('../fonts/geometria/Geometria-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-MediumItalic'),
  url('../fonts/geometria/Geometria-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-Medium'),
  url('../fonts/geometria/Geometria-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-LightItalic'),
  url('../fonts/geometria/Geometria-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-ThinItalic'),
  url('../fonts/geometria/Geometria-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: local('Geometria-Thin'),
  url('../fonts/geometria/Geometria-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


.container-wrapper-new {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0;
  color: #202020;
}

._orientationRight_33e6 .button_4aae {
  margin-bottom: 60px !important;
}

b {
  font-weight: bold;
}

@media only screen and (max-width: 1440px) {
  .container-wrapper-new {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .container-wrapper-new {
    padding: 0 20px;
  }
  .wrap_c028 {
    bottom: 40px !important;
  }
}

@media only screen and (max-width: 767px) {

}

.breadcrumb-holder {
  &.product-page {
    background: transparent;
    width: 100%;
  }

  .breadcrumb {
    max-width: 1406px;
    width: 100%;
    margin: 0 auto;
    padding: 18px 20px;
    font-family: 'Geometria', serif;
    font-weight: 400;
    font-size: 14px;

    ol {
      li {
        margin-bottom: 0;
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: rgba(255, 0, 0, 0);
      height: 3px;
      border-radius: 50px;
      margin-left: 5px;
    }

    &::-webkit-scrollbar-thumb {
      margin-top: 5px;
      background-color: #b7b7b7;
      border-radius: 15px;
    }

    .breadcrumb-item {

      a {
        color: #287C9B;

      }

      &:after {
        color: #1F1F1F;
        content: '/';
        padding: 0 10px;
      }

      &.active {
        color: #1F1F1F;

        &:after {
          content: none;
        }
      }
    }
  }
}

.quick__basket-number {
  label {
    font-size: 16px;
    font-weight: 400;
  }

  input {
    font-size: 14px;
    font-weight: 400;
  }
}

.product-page.card-product-page {
  position: relative;
  background-image: url(/assets/images/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  .product-background {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .fast-buy {
    display: none;
    position: fixed;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0px -4px 30px rgba(0, 0, 0, 0.1);
    z-index: 25;

    &__image {
      border: 1px solid #E5E5E5;
      border-radius: 3px;
      width: 30px;
      height: 30px;
      padding: 2px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > .price-new {
      letter-spacing: -0.03em;
      color: #1F1F1F;
      font-weight: 500;
      font-size: 18px;
      line-height: 75%;
      font-family: "Geometria", serif;

      .old-price-holder {
        height: unset;
        text-align: left;
        margin-bottom: 4px;

        .old-price {
          display: flex;
          align-items: center;
          margin-top: 0;
          text-decoration: none;
          font-size: 11px;
          line-height: 100%;
          letter-spacing: -0.01em;
          color: #9C9C9C;
          position: relative;
          padding: 0;

          &.fast-buy__fixed-price {
            span:first-child {
              text-decoration: line-through;
            }


            .discount.popular__product-discount {
              //background: #E3F5E9;
              //color: #1F1F1F;
            }
          }

          .discount {
            font-size: 9px;
            line-height: 120%;
            letter-spacing: -0.03em;
            color: #fff;
            flex: none;
            order: 0;
            flex-grow: 0;
            background: #E3565E;
            border-radius: 8px;
            padding: 2px 5px;
            margin-left: 5px;
          }
        }
      }
    }

    .one-click-mobile-btn {
      margin-left: auto;
      display: block;
      background: #FFFFFF;
      border: 2px solid #1F1F1F;
      box-sizing: border-box;
      border-radius: 10px;
      color: #1F1F1F;
      padding: 0 14px;
      outline: none;
      transition: 0.28s opacity;
      height: 30px;
      width: 100%;
      max-width: 135px;
      font-size: 14px;
      font-family: "Geometria", serif;
      font-weight: 500;
    }

    .product__buy__top__right {
      flex-grow: 1;
      max-width: 100%;
      margin-left: 30px;

      .product__buy__button, .in-cart {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #44BB6E;
        border-radius: 10px;
        padding: 0 25px;
        flex-grow: 1;
        max-width: 290px;
        border: 0;
        outline: none;
        height: 30px;
        width: 100%;
        font-size: 14px;

        &:hover {
          background: #73CC92;
        }

        &:active {
          background-color: #338C52;
        }

        svg {
          margin-right: 13px;
        }

        span {
          font-family: "Geometria", serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.01em;
          color: #FFFFFF;
        }
      }

      .in-cart {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2px 2px 2px 20px;

        &:hover {
          opacity: 1;
        }

        > p {
          font-family: "Geometria", serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          text-align: center;
          letter-spacing: 0.01em;
          color: #FFFFFF;
          max-width: 58px;
          margin: 0 20px 0 0;
        }

        &__body {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          background: #FFFFFF;
          border-radius: 10px;
          height: 100%;


          > p {
            font-family: 'Geometria', serif;
            font-weight: 700;
            font-size: 12px;
            line-height: 90%;
            text-align: center;
            letter-spacing: -0.01em;
            color: #1F1F1F;
            padding: 0 10px;
            margin-bottom: 0;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 32px;
            min-width: 16px;
            height: 32px;
            border: 0;
            outline: none;
            background: transparent;
            flex-grow: 1;
            border-radius: 10px;
            padding: 0 10px;

            svg {
              margin-right: 0;
            }

            &:focus {
              background: rgba(68, 187, 110, 0.1);
            }
          }
        }
      }
    }
  }

  .product__image .product__slider__wrapper {
    margin: 0 auto;
  }

  .product {
    display: flex;
    align-items: flex-start;
    max-width: 1406px;
    margin: 0 auto;
    position: relative;
    font-family: 'Geometria', serif;
    font-weight: 400;
    padding: 0 20px 100px 20px;

    button {
      padding: 0;
    }

    .category-relinking-links {
      padding-left: 50px;
      padding-top: 10px;
      text-align: center;
    }

    .category-relinking-links > a {
      border: 1px solid #febd0c;
      border-radius: 2px;
      padding: 6px;
      height: 30px;
      font: 14px/18px 'Geometria', sans-serif;
      font-weight: 400;
      color: #202020;
      display: inline-block;
      margin-bottom: 10px;
    }

    .out {
      pointer-events: none !important;
      opacity: 0.6 !important;
    }

    .product__buy {
      background: #FFFFFF;
      border-radius: 20px;
      padding: 20px 0 15px 0;
      margin-bottom: 20px;
      margin-top: 20px;

      &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 5px 30px;

        p {
          font-size: 18px;
          margin-bottom: 0;
        }

        .product__buy__top__left {
          display: flex;
          flex-direction: column;
          margin-right: 20px;
          flex-shrink: 0;

          &.product__buy__price__right {
            flex-direction: unset;
          }

          .product__buy__price {
            p {
              font-family: "Geometria", serif;
              font-weight: 500;
              font-size: 33px;
              line-height: 110%;
              letter-spacing: -0.03em;
              color: #1F1F1F;
            }
          }

          .product__buy__old-price {
            display: flex;

            &.product__buy__price__right__old {
              flex-direction: column;
              margin-left: 6px;
            }

            .discount {
              white-space: nowrap;
              background: #E3565E;
              border-radius: 8px;
              padding: 3px 6px;
              margin-bottom: 4px;

              p {
                font-family: 'Geometria', serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 1;
                letter-spacing: -0.03em;
                margin-bottom: 0;
                color: #FFFFFF;
              }
            }

            > p {
              font-size: 18px;
              line-height: 100%;
              text-decoration-line: line-through;
              color: #9C9C9C;
              margin-right: 5px;
            }
          }
        }

        .product__buy__top__right {
          flex-grow: 1;
          max-width: 290px;

          .product__buy__button, .in-cart {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #44BB6E;
            border-radius: 10px;
            height: 64px;
            padding: 0 25px;
            flex-grow: 1;
            max-width: 290px;
            border: 0;
            outline: none;
            width: 100%;

            &:hover {
              background: #73CC92;
            }

            &:active {
              background-color: #338C52;
            }

            svg {
              margin-right: 13px;
            }

            span {
              font-family: "Geometria", serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 100%;
              letter-spacing: 0.01em;
              color: #FFFFFF;
            }
          }

          .in-cart {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2px 2px 2px 20px;

            &:hover {
              opacity: 1;
            }

            > p {
              font-family: "Geometria", serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              text-align: center;
              letter-spacing: 0.01em;
              color: #FFFFFF;
              max-width: 58px;
              margin-right: 20px;
            }

            &__body {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-grow: 1;
              background: #FFFFFF;
              border-radius: 10px;
              height: 100%;


              > p {
                font-family: 'Geometria', serif;
                font-weight: 700;
                font-size: 16px;
                line-height: 90%;
                text-align: center;
                letter-spacing: -0.01em;
                color: #1F1F1F;
                padding: 0 10px;
              }

              button {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 64px;
                min-width: 32px;
                height: 64px;
                border: 0;
                outline: none;
                background: transparent;
                flex-grow: 1;
                border-radius: 10px;

                svg {
                  margin-right: 0;
                }

                &:focus {
                  background: rgba(68, 187, 110, 0.1);
                }
              }
            }
          }
        }

        .product__buy__left {
          .in-cart {
            &__body {
              button {
                padding: 0 10px;
              }
            }
          }
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        padding: 20px 30px 0 30px;
        border-top: 1px solid rgba(0, 0, 0, 0.14);
        margin-top: 15px;

        .title {
          margin-bottom: 0;
          margin-right: 20px;
          font-family: "Geometria", serif;
          font-weight: 500;
          font-size: 20px;
          letter-spacing: -0.01em;
        }

        .packing {
          display: flex;
          align-items: center;
          margin: 0 -3px;
          overflow-x: auto;
          &::-webkit-scrollbar-track
          {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #F5F5F5;
            border-radius: 10px;
          }

          &::-webkit-scrollbar
          {
            width: 4px;
            height: 4px;
            border-radius: 10px;
            background-color: #b4e3c5;
          }

          &::-webkit-scrollbar-thumb
          {
            background-color: #b4e3c5;
            border-radius: 10px;
          }

          button {
            background: #F8F8F8;
            border-radius: 6px;
            padding: 0 15px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px 3px;
            outline: none;
            border: 2px #F8F8F8 solid;
            transition: 0.28s border-color, 0.28s color;
            display: flex;
            flex-shrink: 0;
            &:focus {
              opacity: 0.9;
            }

            span {
              font-family: "Geometria", serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 100%;
              letter-spacing: 0.01em;
              color: #1F1F1F;
            }

            &.active {
              border: 2px solid #44BB6E;
              pointer-events: none;

              span {
                color: #44BB6E;
              }
            }
          }
        }
      }
    }

    &__image {
      max-width: 630px;
      background: #FFFFFF;
      border-radius: 20px;
      padding: 50px 50px 30px 50px;
      margin-right: 60px;
      flex-grow: 1;
      position: sticky;
      top: 10px;
      overflow: hidden;
      min-width: 400px;
      height: 668px;
      z-index: 24;
      //top: 40%;
      //transform: translateY(-50%);
      //margin-top: 300px;
      &.loading {
        > * {
          opacity: 0;
          visibility: hidden;
        }
      }

      .icon {
        display: block;
        position: relative;
        margin-right: 6px;
        margin-left: 6px;
        cursor: pointer;

        &:hover {
          .discount {
            opacity: 1;
            visibility: visible;
          }
        }

        .discount {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          left: calc(100% + 6px);
          top: 0;
          transition: 0.28s visibility, 0.28s opacity;
          white-space: nowrap;
          background: linear-gradient(0deg, #E3F5E9, #E3F5E9),
          linear-gradient(0deg, #E5E8FC, #E5E8FC), #E6DFFB;
          border-radius: 8px;
          padding: 3px 6px;

          p {
            font-family: 'Geometria', serif;
            font-weight: 500;
            font-size: 12px;
            line-height: 1;
            letter-spacing: -0.03em;
            margin-bottom: 0;
          }
        }
      }


      .product__buy {
        display: none;
        margin: 0 -50px;
        border-top: 1px solid rgba(0, 0, 0, 0.14);
        border-radius: 0;
        background: unset;

        &__top {
          padding: 0 30px;
          width: 100%;

          &__right {
            max-width: 300px;

            button {
              display: block;
              background: #FFFFFF;
              border: 2px solid #1F1F1F;
              box-sizing: border-box;
              border-radius: 10px;
              color: #1F1F1F;
              padding: 0 20px;
              outline: none;
              transition: 0.28s opacity;
              height: 64px;
              width: 100%;

              font-weight: 500;
              font-size: 16px;
              line-height: 100%;
              letter-spacing: 0.01em;

              &:hover {
                background-color: #f8f8f8;
              }

              &:active {
                background-color: #EFEFEF;
              }
            }
          }
        }
      }

      &.sticked {
        padding-bottom: 5px;
        //height: 100%;
        height: 668px;

        .product__slider-nav {
          display: none;
        }

        .product__buy {
          display: flex;
        }

        .slick-dots {
          display: block !important;
          bottom: -28px;
        }

        .slick-list .slick-slide .zoo-item {
          width: 487px;
          height: 487px;
          margin: 0 auto;
        }

        .product__slider {
          margin-bottom: 27px;
        }
      }

      .action {
        display: flex;
        align-items: center;
        padding: 3px 8px;
        background: #DA1E28;
        border-radius: 20px;
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 2;

        span {
          background: #FFFFFF;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin-right: 8px;
        }

        p {
          font-family: "Geometria", serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.01em;
          margin-bottom: 0;
          color: #fff;
        }
      }

      .product__slider {
        margin-bottom: 10px;
        position: relative;

        .slick-dots {
          //bottom: 0;
          button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #E5E5E5;
          }

          .slick-active {
            button {
              background-color: #44BB6E;
            }
          }
        }

        .slick-list {
          width: 100%;
        }

        .slick-arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background: transparent;
          border: 2px solid #9C9C9C;
          transform: translateY(-50%);
          top: 50%;
          z-index: 2;

          svg {
            path {
              stroke: #9C9C9C;
            }
          }

          &.product-slick-left {
            position: absolute;
            left: 3px;

            &:focus {
              outline: none;
            }
          }

          &.product-slick-right {
            position: absolute;
            right: 3px;

            &:focus {
              outline: none;
            }
          }
        }

        .slick-track {
          display: flex;
          margin: 0 auto;

          .slick-slide {

            .zoo-item {
              //width: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transition: transform .5s ease-out;
                opacity: 0;
                visibility: hidden;
              }

              .zoo-img {
                //background-size: cover;
                background-size: contain;
              }
            }
          }
        }

        .slick-list {
          height: 100%;

          .slick-slide {
            .zoo-item {
              //width: 100%;
              //height: 100%;
              //height: 530px;
              position: relative;
              //max-height: 400px;
            }

            &.video {
              iframe {
                width: 100%;
                height: calc(100% + 120px);
                top: -60px;
                bottom: -60px;
              }

              img {
                position: absolute;
                z-index: 4;
                left: 0;
                top: 0;
                max-width: unset;
                width: 100%;
                height: 100%;
              }

              .video-overlay {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                background: rgba(31, 31, 31, 0.7);
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
                z-index: 3;

                svg {
                  width: 80px;
                  height: 80px;
                }
              }
            }
          }
        }
      }

      .slick-dots {
        display: none !important;
      }

      .product__slider-nav {
        margin: 0 auto;
        padding-left: 0;
        width: 92%;
        @media screen and (max-width: 768px) {
          width: auto;
          //max-width: 64%;
        }
        @media screen and (min-width: 376px) and(max-width: 425px) {
          max-width: 56%;
        }
        @media screen and (min-width: 425px) and(max-width: 765px) {
          max-width: 75%;
        }
        @media screen and (min-width: 310px) and(max-width: 340px) {
          //max-width:75%;
          .slick-track {
            justify-content: space-between;

            .slick-slide {
              //min-width: 46px;
            }
          }
        }
        @media screen and (min-width: 340px) and (max-width: 375px) {
          max-width: 72%;
        }

        .slick-list {

          .slick-track {
            margin: 0 auto;
            //width: 100% !important;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 1024px) {
              display: flex;
              justify-content: center;
            }
          }

          .slick-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            margin-left: 5px;
            padding: 3px;
            border: 1px solid #E5E5E5;
            border-radius: 7px;
            transition: border-color 0.28s ease;
            cursor: pointer;
            height: 51px;
            max-width: 51px;
            box-sizing: border-box;
            flex-grow: 1;

            &.video {
              width: 112px;
            }

            img {
              width: 100% !important;
              height: 100%;
              display: flex !important;
              border-radius: 4px;
              object-fit: contain;
              opacity: .5;
              min-width: 43px;

              &.video-poster {
                width: 30px !important;
                height: 30px;
                position: static;
              }

            }

            svg {
              display: flex;
              opacity: 1;
              position: absolute;
            }

            &.slick-current {
              border: 2px solid #44BB6E;
              padding: 2px;
              cursor: default;

              img, svg {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &__description {
      max-width: 674px;
      min-width: 500px;
      overflow-x: hidden;
      z-index: 24;

      .product__code {
        margin-bottom: 10px;

        p {
          font-size: 14px;
          line-height: 120%;
          letter-spacing: -0.01em;
          color: #1F1F1F;

          span {
            color: #727272;
          }
        }
      }

      .product__name {
        margin-bottom: 20px;

        h1 {
          font-size: 32px;
          line-height: 120%;
          letter-spacing: -0.01em;
          color: #1F1F1F;
        }
      }

      .product__status {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .product__status__comments-count {
          margin-right: 18px;

          p {
            //color: #287C9B;
            border-bottom: 1px #287C9B dashed;
            cursor: pointer;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.01em;
          }
        }

        .product__status__availability {
          display: flex;
          align-items: center;
          background: #EFEFEF;
          border-radius: 8px;
          height: 28px;
          padding: 0 8px;

          span {
            background: #44BB6E;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            margin-right: 8px;
            animation: pulse-black 4s infinite;
          }

          p {
            font-size: 16px;
            line-height: 1;
            color: #1F1F1F;
            letter-spacing: -0.01em;
            margin-bottom: -3px;
          }

          .pulse {
            background: #44BB6E;
            box-shadow: 0 0 0 0 #44BB6E;
            animation: pulse-green 2s infinite;

            &.red {
              background: #E3565E;
              box-shadow: 0 0 0 0 #E3565E;
              animation: unset;
            }
          }

          @keyframes pulse-black {
            0% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
            }

            70% {
              transform: scale(1);
              box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
            }

            100% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            }
          }
          @keyframes pulse-green {
            0% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 #44BB6E;
            }

            70% {
              transform: scale(1);
              box-shadow: 0 0 0 5px rgba(51, 217, 178, 0);
            }

            100% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
            }
          }
        }
      }

      .wrap-star {
        margin-bottom: 0;
        margin-right: 18px;

        ul.stars {
          display: flex;
          align-items: center;

          li {
            margin: 0 2px;

            span {
              width: 20px;
              height: 20px;

              &.star1 {
                background: url('/assets/images/star-full.svg');
              }

              &.star2 {
                background: url('/assets/images/star-almost-full.svg');
              }

              &.star3 {
                background: url('/assets/images/star-almost-empty.svg');
              }

              &.star4 {
                background: url('/assets/images/star-empty.svg');
              }

              &.star5 {
                background: url('/assets/images/star-half.svg');
              }
            }
          }
        }
      }

      .product__short-description {
        display: flex;
        justify-content: space-between;
        //margin-bottom: 20px;
        //padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.14);

        .product__short-description__left {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -7px;

          .product__short-description__block {
            margin: 0 7px 7px;

            p {
              display: flex;
              align-items: center;
              font-size: 14px;
              line-height: 120%;
              letter-spacing: -0.01em;
              color: #1F1F1F;
              margin-bottom: 0;


              span {
                display: block;
              }

              span {
                margin-right: 4px;
                color: #727272;
              }
            }
          }
        }

        .product__short-description__right {
          flex-shrink: 0;

          p {
            font-family: "Geometria", serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.01em;
            color: #287C9B;
            border-bottom: 1px #287C9B dashed;
            cursor: pointer;
          }
        }
      }

      .product__timer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 105px;
        color: #1F1F1F;
        padding: 0 30px;
        margin-bottom: 20px;
        margin-top: 20px;

        p {
          margin-bottom: 0;
        }

        .product__timer__left {
          margin-right: 20px;

          .product__timer__title {
            margin-bottom: 4px;

            p {
              font-family: "Geometria", serif;
              font-weight: 500;
              font-size: 24px;
              line-height: 120%;
              letter-spacing: -0.01em;
              color: #1F1F1F;
            }
          }

          .product__timer__description {
            p {
              font-size: 14px;
              line-height: 120%;
              letter-spacing: -0.01em;
              color: #494949;
            }
          }
        }

        .product__timer__right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 -5px;

          .product__timer__block {
            width: 65px;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            border-radius: 10px;
            margin: 0 5px;

            &__number {
              p {
                font-family: "Geometria", serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 120%;
                text-align: center;
                letter-spacing: -0.01em;
              }
            }

            &__units {
              p {
                font-family: "Geometria", serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 110%;
                text-align: center;
                letter-spacing: -0.03em;
                color: #727272;
              }
            }
          }
        }
      }


      .icon {
        display: block;
        position: relative;
        margin-right: 6px;
        margin-left: 6px;
        cursor: pointer;
      }

      .product__buy-phone {
        margin-bottom: 55px;

        .product__buy__top {
          padding-bottom: 20px;

          button {
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.01em;
          }

          input {
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.01em;
            color: #727272;
          }
        }

        fieldset {
          border: 1px solid #D0D0D0;
          border-radius: 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: 0.1s border;

          &.error {
            border: 1px solid #DA1E28;

            legend {
              color: #DA1E28;
            }

            input {
              color: #DA1E28;

              &::placeholder {
                color: #DA1E28;
              }
            }
          }

          legend {
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.01em;
            color: #727272;
            margin-left: 22px;
            width: auto;
            padding: 0 8px;
            transition: 0.1s color;
          }

          label {
            height: 100%;

            input {
              height: 100%;
              margin-left: 30px;
              transition: 0.1s color;

              &::placeholder {
                transition: 0.1s color;
              }
            }
          }

          button {
            display: block;
            background: #FFFFFF;
            border: 2px solid #1F1F1F;
            box-sizing: border-box;
            border-radius: 10px;
            color: #1F1F1F;
            padding: 0 20px;
            margin-top: -6px;
            margin-bottom: -1px;
            margin-right: -1px;
            outline: none;
            width: 47.5%;
            transition: 0.28s opacity;
            height: 64px;

            &:hover {
              background-color: #F8F8F8;
            }

            &:active {
              background-color: #EFEFEF;
            }

            &:focus {
              background-color: #EFEFEF;
            }

            &:disabled {
              background-color: #E8E8E8;
              color: #ABABAB;
              border: 2px solid #E8E8E8;
            }
          }
        }

        .product__buy__bottom {
          justify-content: space-between;
          margin-top: 0;

          button, a {
            width: 302px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            background: #F8F8F8;
            border-radius: 6px;
            font-family: "Geometria", serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.01em;
            color: #1F1F1F;
            border: 0;
            height: 35px;
            outline: none;
            padding: 0;

            &:hover {
              background-color: #EFEFEF;
            }

            &.active {
              border: 2px solid #44BB6E;
              background-color: #E5E5E5;

              span {
                color: #44BB6E;
              }
            }

            &:focus {
              opacity: 0.8;
              border: 2px solid #1F1F1F;
              background: #F8F8F8;
              color: #1F1F1F;
            }

            &:first-child {
              margin-right: 10px;
            }

            svg {
              margin-right: 12px;
            }
          }
        }
      }

      .delivery-payment-mobile {
        display: none;
      }

      .delivery-payment {
        display: flex;
        justify-content: space-between;
        //border-top: 1px solid rgba(0, 0, 0, 0.14);

        &:last-child {
          .column {
            padding-bottom: 0;
          }
        }

        p {
          margin-bottom: 0;
          color: #494949;
        }

        .column {
          width: 50%;
          margin: 0 3px;
          padding: 20px 0;
          border-top: 1px solid rgba(0, 0, 0, 0.14);
          //border-bottom: 1px solid rgba(0, 0, 0, 0.14);
          height: auto;
          overflow: hidden;

          &.cover {
            height: 0;
            padding: 0;
            //border-bottom: unset;
          }

          &.delivery-payment__header {
            display: flex;
            justify-content: space-between;

            span {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid rgba(0, 0, 0, 0.14);
              border-radius: 50%;
            }

            svg {
              transition: 0.28s transform;
            }

            .cover {
              svg {
                transform: rotate(180deg);
              }
            }
          }

          &:first-child {
            p {
              font-family: "Geometria", sans-serif;
              font-weight: 500;
              font-size: 20px;
              line-height: 120%;
              letter-spacing: -0.01em;
              color: #494949;
            }
          }

          .column__string {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 6px;

            &:last-child {
              margin-bottom: 0;
            }

            .icon {
              display: block;
              position: relative;
              margin-right: 6px;
              margin-left: 6px;
              cursor: pointer;

              &:hover {
                .discount {
                  opacity: 1;
                  visibility: visible;
                }
              }

              .discount {
                padding: 15px;
                background: #1F1F1F;
                border-radius: 6px;
                opacity: 0;
                visibility: hidden;
                transition: 0.28s visibility, 0.28s opacity;
                z-index: 10;
                pointer-events: none;

                .arrow,
                .arrow::before {
                  position: absolute;
                  width: 8px;
                  height: 8px;
                  z-index: -1;
                }

                .arrow::before {
                  content: '';
                  transform: rotate(45deg);
                  background: #1F1F1F;
                }

                &[data-popper-placement^='top'] > .arrow {
                  bottom: -4px;
                }

                &[data-popper-placement^='bottom'] > .arrow {
                  top: -4px;
                }

                &[data-popper-placement^='left'] > .arrow {
                  right: -4px;
                }

                &[data-popper-placement^='right'] > .arrow {
                  left: -4px;
                }

                p {
                  color: #FFFFFF;
                  font-family: 'Geometria', serif;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 120%;
                  margin-bottom: 0;
                  width: 230px;
                  letter-spacing: normal;
                }
              }
            }

          }

          .column__tooltip {
            display: flex;
            align-items: center;
          }
        }

        &.payment {
          margin-bottom: 18px;

          .column {
            border-top: 1px solid rgba(0, 0, 0, 0.14);
            //border: 0;
          }
        }
      }

      .advantages {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 30px 30px 20px 30px;
        background: #FFFFFF;
        border-radius: 8px;
        margin-bottom: 30px;

        .advantage {
          display: flex;
          align-items: center;
          width: 31%;
          min-height: 35px;
          margin-bottom: 10px;
          margin-right: 10px;
          cursor: pointer;
          flex: 0 1 calc(33% - 10px);

          .advantage__icon {
            margin-right: 10px;
            //padding: 7px;
            border-radius: 8px;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            padding: 0;
            background-color: #F8F8F8;

            img, svg {
              width: 100%;
              background: #F8F8F8;
              padding: 7px;
              border-radius: 8px;
            }
          }

          .advantage__text {
            p {
              font-size: 14px;
              line-height: 120%;
              letter-spacing: -0.01em;
              color: #1F1F1F;
              margin-bottom: 0;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              word-break: break-word;
            }
          }

          .advantage__tooltip {
            padding: 15px;
            background: #1F1F1F;
            border-radius: 6px;
            opacity: 0;
            visibility: hidden;
            transition: 0.28s visibility, 0.28s opacity;
            z-index: 10;
            cursor: initial;

            a {
              color: #44BB6E;
              text-decoration: underline;
            }

            .arrow,
            .arrow::before {
              position: absolute;
              width: 8px;
              height: 8px;
              z-index: -1;
            }

            .arrow::before {
              content: '';
              transform: rotate(45deg);
              background: #1F1F1F;
            }

            &[data-popper-placement^='top'] > .arrow {
              bottom: -4px;
            }

            &[data-popper-placement^='bottom'] > .arrow {
              top: -4px;
            }

            &[data-popper-placement^='left'] > .arrow {
              right: -4px;
            }

            &[data-popper-placement^='right'] > .arrow {
              left: -4px;
            }

            p {
              color: #FFFFFF;
              font-family: 'Geometria', serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 120%;
              margin-bottom: 0;
              width: 230px;
              letter-spacing: normal;
              white-space: normal;
            }
          }

          &:hover {
            .advantage__tooltip {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .product__image {
    .product__slider-nav {
      .slick-track {
        .slick-slide {
          height: 51px;

          img {
            min-width: unset;
          }
        }
      }
    }
  }
}

.product-relation {
  background-color: #fff;
  width: 100%;
  height: 100%;
  min-height: 445px;
  border-radius: 20px;
  margin-bottom: 55px;
  &__wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  }
  &__title{
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    padding: 20px 30px;
    p {
      font-family: "Geometria", serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
      color: #1F1F1F;
      margin-bottom: 0;
    }
  }
  &__card {
    background-color: #fff;
    width: 33.3%;
    position: relative;
    border-right: 1px solid rgba(0, 0, 0, 0.14);
    transition: .25s;
    &:hover {
      box-shadow: 0 25px 15px -10px rgba(190, 190, 190, 0.1), 0px 0px 40px rgba(0, 0, 0, 0.12) !important;
      transition: .25s;
    }
    &:nth-child(3n) {
      border-right: unset;
    }
    &-action {
      display: flex;
      align-items: center;
      padding: 3px 8px;
      background: #DA1E28;
      border-radius: 20px;
      position: absolute;
      top: 26px;
      left: 5px;
      span  {
        background: #FFFFFF;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        margin-right: 8px;
      }
      p {
        font-family: "Geometria", serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.01em;
        margin-bottom: 0;
        color: #fff;
      }
    }
    &-image {
      padding: 10px;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      img {
        width: 198px;
        height: 198px;
        object-fit: contain;
        border-radius: 10px;
      }
    }

    &-wrapper {
      padding: 10px 10px 20px 10px;
      &-title {
        font-family: "Geometria", serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #1F1F1F;
        max-height: unset;
        //min-height: 60px;

        margin-bottom: 20px;
        //overflow: hidden;
        span {
          display: block;
          height: 40px;
          overflow: hidden;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
      &-price {
        display: flex;
        justify-content: space-between;
        min-height: 42px;
        align-items: center;
        width: 100%;
      }
      &-button {
        width: max-content;
        button {
          display: block;
          background: #FFFFFF;
          border: 2px solid #1F1F1F;
          box-sizing: border-box;
          border-radius: 10px;
          color: #1F1F1F;
          padding: 10px 10px !important;
          margin-top: -6px;
          font-size: 14px;
          //margin-bottom: -1px;
          //margin-right: -1px;
          outline: none;
          width: 100%;
          transition: 0.28s opacity;
          line-height: 1;
          //height: 64px;
          &:hover {
            background-color: #F8F8F8;
          }
        }
      }
    }
    &-discount {
      width: max-content;
      position: absolute;
      left: calc(100% + 5px);
      font-size: 10px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #FFFFFF;
      flex: none;
      order: 0;
      flex-grow: 0;
      background: #E3565E;
      border-radius: 8px;
      padding: 2px 5px;
    }
    &-old-price {
      display: inline-block;
      margin-top: 0;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #9C9C9C;
      position: relative;
      text-decoration: line-through;
    }
    &-old-price-holder {
      margin-bottom: 10px;
    }
    &-price-new {
      letter-spacing: -0.03em;
      color: #1F1F1F;
      font-weight: 500;
      font-size: 20px;
      line-height: 75%;
      font-family: "Geometria",serif;
      padding: 0 10px 0 0;
    }
  }
}

@media screen and (min-width: 1200px) and(max-width: 1366px) {
  .product-page.card-product-page .product__image .product__slider__wrapper {
    max-width: 450px;
  }
  .product__slider {
    margin-bottom: 50px;
  }
  .product-page.card-product-page .product__image .product__slider .slick-dots {
    bottom: -24px;
  }

  .product-page.card-product-page .product__image {
    min-width: 500px;
    padding: 50px 50px 10px 50px;
    min-height: unset;
    height: 604px;
  }
  .product-page.card-product-page .product__image .product__slider .slick-list .slick-slide .zoo-item {
    margin: 0 auto;
  }
  .product-page.card-product-page .product__image.sticked .product__slider .slick-list .slick-slide .zoo-item {
    width: 440px;
    height: 440px;
  }
  .product-page.card-product-page .product__image .product__slider .slick-track .slick-slide .zoo-item .zoo-img {
    background-size: contain;
  }
  .product-page.card-product-page .product__image.sticked .slick-list .slick-slide .zoo-item {

  }
  .product-page.card-product-page .product__image.sticked {
    height: 604px;
  }

  .product-page.card-product-page .product__image {
    max-width: 630px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 50px 50px 30px 50px;
    margin-right: 60px;
    flex-grow: 1;
    position: sticky;
    top: 10px;
    overflow: hidden;
    min-width: 400px;
    height: 604px;
    /* min-height: 668px; */
  }
  .product-page.card-product-page .product__description {
    width: 50%;
  }
  .product-page.card-product-page .product__image.sticked .product__slider {
    margin-bottom: 18px;
  }
  .product-page.card-product-page .product__image .product__slider-nav {
    top: 10px;
  }
}

@media screen and (min-width: 1000px) and(max-width: 1199px) {
  .product-page.card-product-page .product__image {
    height: 100%;
  }
  .product-page.card-product-page .product__description {
    width: 50%;
  }
}

.filter_mob_wrapper {
  @media screen and(max-width: 768px) {
    z-index: 3 !important;
  }
}

.characteristics, .description, .additional-materials, .applications, .delivery-payment-mobile {
  margin-bottom: 30px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    cursor: pointer;
    position: relative;

    &:before {
      position: absolute;
      right: 50.5%;
      bottom: -1px;
      content: '';
      width: 6px;
      height: 1px;
      background-color: #f5f6f0;
    }


    p {
      margin-bottom: 0;
      font-family: "Geometria", sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: #1F1F1F;
    }

    h2 {
      margin-bottom: 0;
      font-family: "Geometria", sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: #1F1F1F;
      margin-left: 0;
      margin-right: auto;
    }

    span {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.14);
      border-radius: 50%;

      svg {
        transition: 0.28s transform;
      }

      &.cover {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__body {
    height: auto;
    overflow: hidden;

    &.cover {
      height: 0;
    }

    .text {
      padding-top: 10px;

      b {
        font-weight: 700;
      }

      ul {
        display: inline-block;
        margin-left: 20px;
        font-family: "Geometria", serif;
        font-weight: 400;
        margin-bottom: 20px;
      }

      p, li {
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: #494949;

        &::marker {
          display: block;
        }
      }
    }
  }

  .characteristics-table, .additional-materials-table {
    table {
      margin-bottom: 0;

      tbody {
        tr {
          border-top: 1px solid rgba(0, 0, 0, 0.14);

          th:before {
            position: absolute;
            right: 5px;
            bottom: -1px;
            content: '';
            width: 6px;
            height: 1px;
            background-color: #f5f6f0;
          }

          th, td {
            position: relative;
            width: 50%;
            padding: 10px 0;
            border: 0;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.01em;
            color: #494949;

            a {
              font-size: 16px;
              line-height: 140%;
              letter-spacing: -0.01em;
              text-decoration-line: underline;
              color: #287C9B;
            }
          }

          &:nth-child(1) {
            border-top: 0;
          }
        }
      }
    }
  }
}

.description__header {
  &:before {
    position: absolute;
    right: 50.5%;
    bottom: -1px;
    content: '';
    width: 6px;
    height: 1px;
    background-color: transparent;
  }
}

.description .description__body .text ol {
  padding-left: 30px;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::after {
  font-size: 12px;
  content: attr(data-tooltip);
  position: absolute;
  width: auto;
  left: 0;
  top: -6vh;
  background: #1F1F1F;
  color: #fff;
  padding: 0.5em;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  opacity: 0;
  transition: 1s;
}

[data-tooltip]:hover::after {
  opacity: 1;
  left: 280px;
}

.checkbox {
  .error-text.error-text__monobank {
    bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  [data-tooltip]:hover::after {
    top: -5vw;
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  [data-tooltip]:hover::after {
    top: -9vw;
    left: 0;
    width: auto;

  }
}

@media screen and(max-width: 380px) {
  [data-tooltip]:hover::after {
    top: -16vw;
    left: 0;
    width: auto;
  }
}

.applications.scope {
  border-bottom: none;
  padding-bottom: 0;
  position: relative;
}

//.applications__tag{
//    position: relative;
//}
//.applications__tag:after {
//    content: '';
//    width: 100%;
//    height: 40px;
//    position: absolute;
//    bottom: 0;
//    left: 0;
//    background: linear-gradient(180deg, rgba(242, 247, 241, 0.5) 0%, rgba(242, 247, 241, 0.25) 40.1%, rgba(242, 247, 241, 0.94) 58.33%);
//}

.applications__tag.applications__more:after {
  content: unset;
  transition: .25s;
}

@media (hover: hover) {
  .callback__mobile {
    &:hover .callback__more-phone {
      display: block;
      position: absolute;
      top: 55px;
      right: 0;
      z-index: 102;
    }
  }
}

.active-block {
  display: block !important;
}

.applications {
  &__tag.applications__more.cover {
    height: 0;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    margin-bottom: 10px;
    cursor: pointer;

    p {
      margin-bottom: 0;
      font-family: "Geometria", sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: #1F1F1F;
    }

    span {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.14);
      border-radius: 50%;

      svg {
        transition: 0.28s transform;
      }

      &.cover {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__tag {
    overflow: hidden;
    font-size: 0;

    span {
      font-size: 16px;
    }

    &.cover {
      height: 0;

      svg {

      }
    }

    a {
      margin-right: 15px;

      &:last-child {
        margin-right: 5px;
      }
    }

    &.applications__more {
      height: auto;

    }
  }

  &__more-hide-button {
    display: none;
  }

  &__more-button {
    &.cover {
      display: none;
    }
  }

  &__more-button {
    margin-top: 10px;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.01em;
    border-bottom: 1px solid #287C9B;
    border-bottom-style: dotted;
    color: #287C9B;
    border-left: none;
    border-top: none;
    border-right: none;
    background: unset;
  }

}

.reviews {
  @-webkit-keyframes pulse {
    0% {
      opacity: 0;
      -webkit-transform: scaleX(1);
      transform: scaleX(1)
    }
    50% {
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03)
    }
    to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1)
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0;
      -webkit-transform: scaleX(1);
      transform: scaleX(1)
    }
    50% {
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03)
    }
    to {
      opacity: 1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1)
    }
  }

  .animate__pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out
  }

  #comment-list-holder {
    background: #FFFFFF;
    border-radius: 20px;
    padding-bottom: 30px;

    .reviews__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.14);
      padding: 20px 30px;
      margin-bottom: 60px;
      position: relative;
      z-index: 3;

      &__left {
        p {
          margin-bottom: 2px;
          font-family: "Geometria", serif;
          font-weight: 500;
          font-size: 24px;
          line-height: 120%;
          letter-spacing: -0.01em;
          color: #1F1F1F;
        }

        .wrap-star {
          display: flex;
          align-items: center;

          ul.stars {
            margin-right: 8px;

            li {
              span {
                width: 12px;
                height: 12px;
                background-size: cover;
              }
            }
          }

          p {
            font-family: "Geometria", serif;
            font-weight: 500;
            font-size: 12px;
            line-height: 1;
            letter-spacing: -0.03em;
            color: #1F1F1F;
            margin-bottom: 0;
            margin-top: 3px;
          }
        }
      }

      &__right {
        button {
          background: #F8F8F8;
          border-radius: 8px;
          font-family: "Geometria", serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.01em;
          color: #1F1F1F;
          padding: 15px 20px;
          outline: none;
          border: 0;

          &:hover {
            background-color: #EFEFEF;
          }
        }
      }
    }


    .review-block-item {
      padding: 0 30px;
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      animation: pulse;
      animation-duration: 1.5s;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &__avatar {
        margin-right: 10px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: linear-gradient(0deg, #E3F5E9, #E3F5E9), linear-gradient(0deg, #E5E8FC, #E5E8FC), #E6DFFB;
          color: #44BB6E;
          font-family: "Geometria", serif;
          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
          text-transform: uppercase;
        }

        .line {
          background: #F8F8F8;
          border-radius: 20px;
          height: calc(100% - 50px);
          position: absolute;
          width: 3px;
          top: 50px;
          left: 50px;
        }
      }

      &__body {
        flex-grow: 1;

        .review-block-item-text {
          background: #F8F8F8;
          border-radius: 10px;
          padding: 15px;

          &-answer {
            display: flex;
            margin-top: 10px;
            background: unset;
            border-radius: 0;
            padding: 0;

            .review-block-item__body {
              background: #F8F8F8;
              border-radius: 10px;
              padding: 15px;
              flex-grow: 1;
            }
          }
        }

        p {
          margin-bottom: 0;
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.01em;
        }

        .text {
          margin-bottom: 10px;
        }

        button {
          border: 0;
        }

        .comment {
          font-size: 14px;
          font-family: "Geometria", serif;
          font-weight: 500;
          color: #287C9B;
          border-bottom: 1px #287C9B dashed;
          background: transparent;
          padding: 0;
        }

        .title-review {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2px;

          span {
            font-family: "Geometria", serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.01em;
            color: #1F1F1F;
          }

          .data-review {
            font-family: "Geometria", serif;
            font-weight: 500;
            font-size: 12px;
            line-height: 110%;
            letter-spacing: -0.03em;
            color: #9C9C9C;
          }
        }

        .wrap-star {
          margin: 0 -2px 10px -2px;

          ul.stars {
            li {
              span {
                width: 12px;
                height: 12px;
                background-size: cover;
              }
            }
          }
        }
      }

      .title-review {

      }
    }

    .reviews__no-comments {
      display: flex;
      justify-content: center;
      align-items: center;

      .description {
        text-align: center;
        margin-bottom: 10px;

        p {
          margin-bottom: 0;
        }

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 15px;
          position: relative;

          ul.stars {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 3;

            li {
              margin: 0 5px;
              list-style: none;

              span {
                width: 50px;
                height: 50px;
                display: block;
                background-size: cover !important;

                &.star1 {
                  background: url('/assets/images/star-full.svg');
                }

                &.star2 {
                  background: url('/assets/images/star-almost-full.svg');
                }

                &.star3 {
                  background: url('/assets/images/star-almost-empty.svg');
                }

                &.star4 {
                  background: url('/assets/images/star-empty.svg');
                }

                &.star5 {
                  background: url('/assets/images/star-half.svg');
                }
              }
            }
          }

          .bg {
            position: absolute;
            left: 0;
          }
        }

        &__title {
          margin-bottom: 8px;
          position: relative;
          z-index: 3;

          p {
            font-family: "Geometria", serif;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #1F1F1F;
          }
        }

        &__text {
          position: relative;
          z-index: 3;

          p {
            font-size: 14px;
            line-height: 120%;
            text-align: center;
            letter-spacing: -0.01em;
            color: #727272;
            margin-bottom: 55px;
          }
        }

        &__button {
          background: #44BB6E;
          border-radius: 8px;
          border: 0;
          outline: none;
          height: 44px;
          font-family: "Geometria", serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          letter-spacing: 0.01em;
          color: #FFFFFF;
          padding: 0 20px;

          &:hover {
            background-color: #73CC92;
          }

          &:active {
            background-color: #338C52;
          }
        }

      }
    }

    .load-more {
      border-radius: 8px;
      border: 2px solid #1F1F1F;
      height: 44px;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 60px);
      margin: 30px 30px 0 30px;
      outline: none;

      svg {
        margin-right: 16px;
      }

      span {
        font-family: "Geometria", serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.01em;
        color: #1F1F1F;
      }
    }
  }
}

.comparison-page table {
  z-index: 10;
}

@media screen and (max-width: 1200px) {
  .comparison-page table {
    top: 407px;
  }
}

@media screen and (max-width: 950px) {
  .comparison-page table {
    top: 377px;
  }
}

.comparison-page .slider-comparison .characteristics li {
  border: none !important;
}

@media screen and (max-width: 485px) {
  .comparison-page table {
    top: 483px
  }
}

@media screen and (max-width: 475px) {
  .comparison-page table {
    top: 470px;
  }
}

@media screen and (max-width: 400px) {
  .comparison-page table {
    top: 466px
  }
}

@media screen and (max-width: 375px) {
  .comparison-page table {
    top: 469px
  }
}

.comparison-page .slider-comparison {
  button {
    top: 190px !important;
  }

  .characteristics {
    border: none;
  }

  .slick-track {
    margin: auto;
  }

  .compare-block {
    display: none !important;
  }

  table {
    padding-bottom: 2px !important;
  }

  @media screen and (min-width: 1024px) {
    max-width: 50vw !important;
    margin-top: 50px;
    min-width: 45vw;
    .slider-comparison .characteristics li {
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 480px) {
    width: 950px;
    .slider-comparison .characteristics li {
      overflow-x: auto;
      border: none;
    }
  }
  @media screen and (min-width: 376px) and(max-width: 415px) {
    margin-top: 3.1vw;
  }
  @media screen and (min-width: 310px) and(max-width: 340px) {
    margin-top: 5vw;
    .slider-comparison .characteristics {
      padding: 1px 0 0;
    }
  }
  @media screen and (min-width: 340px) and (max-width: 375px) {
    margin-top: 5vw;
    .slider-comparison .characteristics {
      padding: 1px 0 0;
    }
  }

  .block-buy {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 400px) {
    .box-category-holder .about-title {
      max-height: 60px;
      margin-bottom: 5px;
      min-height: 55px;
    }
  }
}

.comparison-wrapper .filter-serv .column {
  width: auto !important;
}

.added-to-cart.modal-u.show {
  visibility: visible;
  opacity: 1;
}

.added-to-cart.modal-u.show {

  .modal-u__body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 100%;
  }
}

.modal-u {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: 0.28s opacity, 0.28s visibility;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 102;
  font-family: 'Geometria', serif;
  font-weight: 400;


  .modal-u__body, > .block-start {

  }

  &.video-player {
    padding-top: 0;

    .video-player__body {
      max-width: 90vw;
      height: 85vh;
      margin-top: 100px;

      iframe {
        width: 100%;
        height: 100%;
      }

      .video-player-close {
        position: absolute;
        right: -17px;
        top: -17px;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F8F8F8;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }

  fieldset {
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    legend {
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #727272;
      margin-left: 22px;
      width: auto;
      padding: 0 8px;
    }

    label {
      height: 100%;

      input {
        height: 100%;
        margin-left: 30px;
      }
    }

    button {
      display: block;
      background: #FFFFFF;
      border: 2px solid #1F1F1F;
      box-sizing: border-box;
      border-radius: 10px;
      color: #1F1F1F;
      padding: 0 20px;
      margin-top: -6px;
      outline: none;
      width: 47%;
      transition: 0.28s opacity;

      &:disabled {
        opacity: 0.5;
      }

      &:focus {
        opacity: 0.9;
      }
    }
  }

  p.one-click-description {
    text-align: center;
    margin-bottom: 30px;
  }

  button.btn-one-click {
    background: #44BB6E;
    border-radius: 8px;
    height: 44px;
    flex-grow: 1;
    border: 0;
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    width: 100%;
    outline: none;
    margin-top: 30px;
    transition: 0.28s opacity;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }


  .feedback-bg {
    background: #FFFFFF;
    border-radius: 20px;
    max-width: 450px;
    flex-grow: 1;
    padding: 20px;

    .feedback-holder {
      max-width: unset;

      &.product-feedback {
        input {
          font-size: 16px;
        }
      }
    }

    .line {
      border-bottom: 1px solid rgba(0, 0, 0, 0.14);
      display: block;
      margin: 0 -20px 30px -20px;
      position: relative;

      &.need-register {
        p {
          position: absolute;
          left: 50%;
          top: -8px;
          padding: 0 10px;
          background: #fff;
          transform: translateX(-50%);
          font-size: 14px;
          line-height: 120%;
        }
      }
    }

    .feedback {
      &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 44px;
        margin-bottom: 20px;

        span {
          margin-bottom: 0;
        }

        .close-feedback {
          position: absolute;
          top: 0;
          right: 0;
          width: 44px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #F8F8F8;
          border-radius: 8px;
          cursor: pointer;
        }
      }

      &__need-register {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        p {
          font-size: 16px;
          line-height: 140%;
          letter-spacing: -0.01em;
          color: #1F1F1F;
          max-width: 181px;
          margin-bottom: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #F8F8F8;
          border-radius: 8px;
          height: 44px;
          flex-grow: 1;
          max-width: 129px;
          border: 0;
          outline: none;
          text-decoration: none;

          svg {
            margin-right: 21px;
          }

          span {
            font-family: "Geometria", serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.01em;
            color: #1F1F1F;
          }
        }
      }

      &__body {
        .form-height-column {
          width: 100%;

          label {
            margin-bottom: 0;
          }

          input {
            border: 1px solid #D0D0D0;
            border-radius: 10px;
            padding: 0 15px;

            &::placeholder {
              color: #727272;
            }
          }
        }

        .wrap-star {
          float: unset;
          width: unset;
          text-align: left;
          padding: 0;
          margin-bottom: 15px;

          ul.stars {
            display: flex;
            align-items: center;

            li {
              margin: 0 2px;

              span {
                width: 28px;
                height: 28px;
                background-size: cover !important;
                background: url(/assets/images/star-full.svg);

                &.star1 {
                  background: url('/assets/images/star-full.svg');
                }

                &.star2 {
                  background: url('/assets/images/star-almost-full.svg');
                }

                &.star3 {
                  background: url('/assets/images/star-almost-empty.svg');
                }

                &.star4 {
                  background: url('/assets/images/star-empty.svg');
                }
              }
            }
          }
        }

        .form-height {
          .input-width {
            margin-bottom: 15px;
          }

          textarea {
            border: 1px solid #D0D0D0;
            border-radius: 10px;
            padding: 15px;
            height: 130px;

            &::placeholder {
              color: #727272;
            }
          }
        }

        .textarea-group {
          min-height: 130px;
        }

        .form-btn-buy {
          height: 64px;

          .btn-buy {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #44BB6E;
            height: 64px;
            font-family: "Geometria", serif;
            font-weight: 500;
          }
        }
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  &__body {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    position: relative;
    max-width: 450px;
    flex-grow: 1;

    .added-to-cart__empty-cart {
      padding-top: 20px;
    }

    span {
      position: absolute;
      top: 20px;
      right: 20px;
      background: #F8F8F8;
      border-radius: 8px;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    p {
      max-width: 280px;
      flex-grow: 1;
      font-size: 14px;
      line-height: 120%;
      text-align: center;
      letter-spacing: -0.01em;
      color: #1F1F1F;
      margin: 0 auto 20px auto;
    }

    button {
      background: #44BB6E;
      border-radius: 8px;
      height: 44px;
      flex-grow: 1;
      border: 0;
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      width: calc(100% - 40px);
      outline: none;
    }
  }
}

.wrap-holder.wrap-slider-rec-products {
  max-width: 1368px;
  margin: 0 auto 100px auto;
  padding: 0;
  border-bottom: 0;

  .wrap {
    margin: 0;
    background: #FFFFFF;
    //border: 1px solid #FFFFFF;
    border-radius: 20px;

  }
}

.similar-products {
  .slick-list {
    overflow: visible;
  }
}

@media screen and(min-width: 768px) {
  .desktop-controls {
    display: flex !important;
  }
  .mobile-controls {
    display: none !important;
  }
  .mobile-bin {
    display: none !important;
  }
}

@media screen and(max-width: 768px) {
  .desktop-controls {
    //display: none!important;
  }
  .mobile-controls {
    display: flex;
  }
  .mobile-bin {
    display: block;
    text-align: center;
  }
}

.invalid-popup {
  //border-color: red!important;
}

.wrap-slider-rec-products {
  &__header {
    padding: 30px 30px 25px 30px;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5;

    .viewed__title {
      text-align: left;
    }

    p {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: #1F1F1F;
      margin-bottom: 0;
      width: 100%;
    }

    .buttons {
      display: flex;
      align-items: center;

      .wrap-slider-rec-products-prev, .wrap-slider-rec-products-next {
        display: block;
        width: 44px;
        height: 44px;
        background: #F8F8F8;
        border-radius: 8px;
        border: 0;

        &:hover {
          background-color: #EFEFEF;
        }

        &:nth-child(1) {
          margin-right: 5px;
        }
      }
    }
  }

  .category-links.slider-tovar {

    .slick-list {
      //border-bottom: 1px solid #E5E5E5;
      border-top: 1px solid #E5E5E5;
      border-bottom: 1px solid #E5E5E5;
      z-index: 1;
    }

    .slick-track {
      display: flex;
    }

    .holder {
      padding: 0;
      height: 509px;
      width: 25%;
      //min-width: 333px;
      .box-category-holder {
        height: 100%;

        .box-category {
          box-shadow: unset;
          border: 1px #f5f5f5 solid;
          border-radius: 0;
          height: 100%;

          &:hover {
            height: unset;

            .title {
              position: static;

            }
          }


          .substrate {
            height: unset;

            .title {
              margin-bottom: 5px;
            }
          }

          .wrap-star {
            display: flex;
            align-items: center;
            margin-bottom: 23px;

            ul.stars {
              display: flex;
              align-items: center;
              margin-right: 0;

              li {
                margin: 0 2px;

                span {
                  width: 12px;
                  height: 12px;
                  background-size: cover !important;

                  &.star1 {
                    background: url('/assets/images/star-full.svg');
                  }

                  &.star2 {
                    background: url('/assets/images/star-almost-full.svg');
                  }

                  &.star3 {
                    background: url('/assets/images/star-almost-empty.svg');
                  }

                  &.star4 {
                    background: url('/assets/images/star-empty.svg');
                  }
                }
              }
            }

            .quantity {
              font-size: 12px;
              line-height: 140%;
              letter-spacing: -0.03em;
              color: #1F1F1F;
              font-family: "Geometria", serif;
              font-weight: 500;
            }
          }

          .item-category {
            padding: 20px 0;
            width: 290px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;

            .action {
              display: flex;
              align-items: center;
              padding: 3px 8px;
              background: #DA1E28;
              border-radius: 20px;
              position: absolute;
              top: 26px;
              left: 5px;

              span {
                background: #FFFFFF;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                margin-right: 8px;
              }

              p {
                font-family: "Geometria", serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: -0.01em;
                margin-bottom: 0;
                color: #fff;
              }
            }

            .image-holder {
              padding: 0;
              margin-bottom: 23px;

              &.image-size {
                width: 100%;
                height: 290px;
              }

              img {
                border-radius: 10px;
                height: 100%;
              }
            }

            .description-category {
              max-height: unset;
              padding: 0;
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              width: 100%;

              .block-buy {
                //margin-top: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                //margin-bottom: 20px;
                margin: 0;

                &:before, &:after {
                  content: none;
                }

                .price-new {
                  letter-spacing: -0.03em;
                  color: #1F1F1F;
                  font-weight: 500;
                  font-size: 26px;
                  line-height: 75%;
                  font-family: "Geometria", serif;
                  padding: 0 10px 0 0;

                  .old-price-holder {
                    height: unset;
                    margin-bottom: 5px;

                    .old-price {
                      display: inline-block;
                      margin-top: 0;
                      //text-decoration: none;
                      font-size: 16px;
                      line-height: 100%;
                      letter-spacing: -0.01em;
                      color: #9C9C9C;
                      position: relative;
                      text-decoration: line-through;

                      .discount {
                        width: max-content;
                        position: absolute;
                        left: calc(100% + 5px);
                        font-size: 10px;
                        line-height: 120%;
                        letter-spacing: -0.03em;
                        color: #FFFFFF;
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                        background: #E3565E;
                        border-radius: 8px;
                        padding: 2px 5px;
                      }
                    }
                  }
                }

                .btn-buy {
                  display: flex;
                  align-items: center;
                  padding: 15px;
                  font-size: 14px;
                  line-height: 100%;
                  letter-spacing: 0.01em;
                  font-family: "Geometria", serif;
                  font-weight: 500;
                  border: 0;
                  height: 44px;
                  max-width: 135px;
                  flex-grow: 1;
                  min-width: unset;
                  justify-content: center;

                  svg {
                    margin-right: 15px;
                  }

                  p {
                    margin-bottom: 0;
                  }

                  @media only screen and (max-width: 1100px) {
                    svg {
                      margin-right: 0;
                    }
                    p {
                      display: none;

                    }
                  }
                }

                @media only screen and (max-width: 1000px) {
                  //.btn-buy{
                  //    display: none;
                  //}
                  .in-cart-block {
                    //display: flex !important;
                  }
                }

                .in-cart-block {
                  width: 36px;
                  height: 36px;
                  align-items: center;
                  justify-content: center;
                  display: none;
                  margin: 0 3px;

                  label {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    position: relative;


                    &:before {
                      content: none;
                      display: none;
                    }

                    input {
                      display: none;
                      opacity: 0;
                      visibility: hidden;

                      &:checked + span {
                        background: #F8F8F8;
                        border: 2px #44BB6E solid;

                        svg {
                          path {
                            fill: #44BB6E;

                          }
                        }

                        span.added {
                          display: flex;
                        }
                      }
                    }

                    span {
                      background: #44BB6E;
                      border-radius: 10px;
                      width: 36px;
                      height: 36px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    span.added {
                      width: 18px;
                      height: 18px;
                      border-radius: 50%;
                      background: #44BB6E;
                      display: none;
                      align-items: center;
                      justify-content: center;
                      position: absolute;
                      right: -5px;
                      top: -5px;
                    }
                  }

                }
              }

              &:hover {
                .title {
                  position: static;

                }
              }

              .title {
                .about-title {
                  height: auto;
                  text-align: left !important;
                  max-width: unset;
                  font-family: "Geometria", serif;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 140%;
                  letter-spacing: -0.01em;
                  color: #1F1F1F;
                  max-height: unset;
                  -webkit-line-clamp: 3;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  padding: 0;


                  a {
                    display: block;

                  }
                }
              }
            }
          }

          .wishlist-block {
            position: absolute;
            top: 20px;
            right: 20px;
            margin-bottom: 0;

            label {
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #F8F8F8;
              border-radius: 10px;
              width: 100%;
              height: 100%;
              cursor: pointer;

              &.active {
                background: #44BB6E;
              }

              &:before {
                content: none;
                display: none;
              }

              input {
                display: none;
                opacity: 0;
                visibility: hidden;

                &:checked + span {
                  background: #44BB6E;

                  svg {
                    path {
                      stroke: #fff;
                    }
                  }
                }
              }

              span {
                background: #F8F8F8;
                border-radius: 10px;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                  opacity: 0.65;
                }
              }
            }
          }

          .compare-block {
            position: absolute;
            top: 60px;
            right: 20px;
            margin-bottom: 0;

            label {
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #F8F8F8;
              border-radius: 10px;
              width: 100%;
              height: 100%;
              cursor: pointer;

              &.active {
                background: #44BB6E;
              }

              &:before {
                content: none;
                display: none;
              }

              input {
                display: none;
                opacity: 0;
                visibility: hidden;

                &:checked + span {
                  background: #44BB6E;

                  svg {
                    path {
                      fill: #fff;
                    }
                  }
                }
              }

              span {
                background: #F8F8F8;
                border-radius: 10px;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                  opacity: 0.75;
                }
              }
            }
          }

        }
      }

      &.filtered {
        display: none;
      }
    }
  }

  .slick-dots {
    position: static;
    padding-top: 30px;
    padding-bottom: 20px;

    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #E5E5E5;

      &.slick-active {
        background: #44BB6E;
      }
    }
  }
}

.viewed_product__wrapper {
  max-width: 1406px;
  margin: 0 auto;
}

//Desktop
@media only screen and (max-width: 1200px) {
  .product-page.card-product-page .product .product__buy__top .product__buy__top__left .product__buy__price p {
    font-size: 33px;
  }
  .product-page.card-product-page .product__description .product__timer {
    flex-direction: column;
    height: unset;

    .product__timer__left {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

//Tablet
@media only screen and (max-width: 1000px) {
  .breadcrumb-holder ol li {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .breadcrumb-holder .breadcrumb {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 14px 20px;
    overflow: auto;
  }
  .breadcrumb-holder .breadcrumb .breadcrumb-item a span {
    white-space: nowrap;
  }
  .breadcrumb-holder .breadcrumb .breadcrumb-item.active {
    span {
      white-space: nowrap;
      margin-right: 20px;
    }
  }
  .modal-u.video-player {
    z-index: 25;

    .video-player__body {
      max-height: 500px;
      margin-top: 0;
    }
  }

  .product-page.card-product-page {
    .fast-buy {
      display: flex;

      .one-click-mobile-btn {
        border-radius: 6px;
      }
    }

    .product {
      flex-direction: column;
      padding-bottom: 80px;

      &__image {
        position: static;
        top: 0;
        max-width: unset;
        width: 100%;
        margin: 0 0 30px 0;

        .product__slider {
          max-width: 450px;
          margin: 46px auto 10px auto;
          height: 450px;

          .slick-list {
            .slick-track {
              .slick-slide {
                height: 450px;
              }
            }
          }
        }
      }

      &__description {
        max-width: unset;
        min-width: unset;
      }

      .product__buy__top {
        .product__buy__top__left {
          flex-direction: unset;

          .product__buy__price {
            margin-right: 6px;
          }

          .product__buy__old-price {
            flex-direction: column;
          }
        }
      }

    }

    .wrap-holder.wrap-slider-rec-products {
      margin-bottom: 80px;

      .category-links.slider-tovar {
        .holder {
          .box-category-holder {
            .box-category {
              .item-category {
                .image-holder {
                  height: 290px;
                  margin-bottom: 7px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .description-category {
                  .block-buy {
                    .btn-buy {
                      min-width: unset;

                      svg {
                        margin-right: 0;
                      }

                      p {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//Mobile
@media only screen and (max-width: 767px) {

  .product-page.card-product-page .product__description .product__timer .product__timer__left .product__timer__title p {
    font-size: 20px;
  }
  .modal-u.video-player {
    .video-player__body {
      max-height: 300px;
    }
  }
  .modal-u .feedback-bg {
    .line {
      margin-bottom: 15px;
    }

    .feedback__need-register {
      margin-bottom: 15px;

      > p {
        margin-right: 5px;
      }

      a {
        svg {
          margin-right: 5px;
        }
      }
    }

    .feedback__header {
      margin-bottom: 15px;

      .close-feedback {
        width: 35px;
        height: 35px;
        top: 50%;
        transform: translateY(-50%);

        svg {
          width: 10px;
        }
      }
    }

    .feedback__body {
      .form-btn-buy {
        height: 44px;
        margin-bottom: 0;

        .btn-buy {
          height: 44px;
        }
      }
    }
  }

  .breadcrumb-holder {
    padding: 5px;
    width: auto;
    display: flex;
    white-space: nowrap;
    overflow: auto;

    .breadcrumb {
      padding: 10px 10px 5px 10px;
    }
  }
  .added-to-cart {
    .modal-u__body {
      padding: 20px !important;

      .added-to-cart__header {
        padding-bottom: 15px;

        p {
          font-size: 16px;
          line-height: 140%;
        }

        .close {
          width: 30px;
          height: 30px;

          svg {
            width: 11px;
          }
        }
      }

      .added-to-cart__body-wrapper {
        .added-to-cart__body {
          margin: 15px 0;

          .added-to-cart__product {
            &__image {
              max-width: 55px;
              height: 55px;
              margin-right: 10px;
            }

            &__price .price {
              font-size: 16px;

            }

            &__price-count {
              p {
                font-size: 14px;
              }

            }

            &__description {
              p {
                font-size: 12px;
                font-family: "Geometria", serif;
                font-weight: 500;
              }

              .count {
                font-size: 14px;
              }

              .price {
                margin-left: 10px;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .added-to-cart__total {
        p {
          font-size: 16px;
        }
      }
    }

    &__controls {
      flex-direction: column;

      button {
        width: 100%;
        margin: 0 !important;
        max-width: unset;

        &:first-child {
          margin-top: 10px !important;
          order: 2;
        }
      }
    }
  }
  div.to-tablet {
    display: flex !important;
    flex-direction: column;
  }
  .product-page {
    &.card-product-page {
      .product__description {
        .delivery-payment {
          .column {
            &:first-child {
              p {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }

  .product-page.card-product-page {
    .product {
      .product__description {
        .delivery-payment {
          p {
            width: 80%;
          }

          .column {
            &:first-child {
              font-size: 22px;
            }

            .column__tooltip {
              width: 90%;
            }
          }
        }
      }

      padding: 0;

      &__image {
        border-radius: 0;
        min-width: unset;
        border-bottom: 1px solid rgba(0, 0, 0, 0.14);
        margin-bottom: 0;
        padding: 40px 20px 20px 20px;
        height: 100%;

        .product__slider {
          max-width: unset;
          padding: 0;
          margin: 0 0 20px 0;

          .slick-arrow {
            z-index: 10;

            svg {
              width: 7px;
            }
          }

          .slick-arrow.product-slick-left {
            width: 30px;
            height: 30px;
          }

          .slick-arrow.product-slick-right {
            width: 30px;
            height: 30px;
          }

          //.slick-list{
          //    .slick-track{
          //        .slick-slide{
          //            height: 280px;
          //        }
          //    }
          //}
        }
      }

      &__description {
        width: 100%;

        .product__short-description {
          display: none;
        }

        //> div{
        //    display: none !important;
        //}
        .product {
          &__name {
            padding: 0 20px;
            margin-bottom: 0;
            background: #fff;

            h1 {
              font-size: 20px;
              line-height: 120%;
              margin-bottom: 0;
            }
          }

          &__status {
            margin-bottom: 0;
            padding: 5px 20px 20px 20px;
            background: #fff;

            .wrap-star {
              margin-right: 10px;

              .stars {
                li {
                  span {
                    width: 16px;
                    height: 16px;
                    background-size: cover !important;
                  }
                }
              }
            }

            .product__status__comments-count {
              margin-right: 10px;

              p {
                font-size: 14px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                letter-spacing: 0.01em;
              }
            }

            .product__status__availability {
              height: 23px;

              p {
                font-size: 14px;
              }
            }
          }

          &__scort-description {
            display: none;
          }

          &__timer {
            padding: 30px 20px !important;
            background: #F8F8F8;
            margin-bottom: 0;

            &__left {
              .product__timer {
                p {
                  font-size: 20px;
                }
              }
            }

            &__right {
              .product__timer__block {
                height: 57px;
              }
            }
          }

          &__buy {
            border-radius: 0;
            border-bottom: 1px solid #E5E5E5;
            margin-bottom: 0;
            margin-top: 0;
            flex-direction: column;

            &__top {
              padding: 0 20px 5px 20px;

              &__left {
                flex-direction: column;
                margin-right: 0 !important;

                .product__buy__hover {
                  display: none;
                }

                .product__buy__old-price {
                  .discount {
                    width: max-content;
                  }
                }
              }
            }

            &__bottom {
              flex-direction: column;
              align-items: flex-start;
              padding-right: 0;

              .title {
                margin-right: 0;
                margin-bottom: 10px;
              }

              .packing {
                overflow-x: auto;
                width: 100%;
                padding-bottom: 10px;

                button {
                  flex-shrink: 0;
                  white-space: nowrap;
                }
              }
            }
          }

          &__buy.to-tablet {
            .product__buy__top {
              &__right {
                display: block;
                max-width: unset;

                .one-click-mobile-btn {
                  display: block;
                  background: #FFFFFF;
                  border: 2px solid #1F1F1F;
                  box-sizing: border-box;
                  border-radius: 10px;
                  color: #1F1F1F;
                  padding: 0 20px;
                  outline: none;
                  transition: 0.28s opacity;
                  height: 64px;
                  width: 100%;
                  font-size: 16px;
                }

                .in-cart {
                  max-width: unset;
                }

                .product__buy__button {
                  max-width: unset;
                }
              }
            }

            &.one-click-mobile {
              border-bottom: 0;

              .product__buy__top {
                padding-bottom: 0;
              }
            }
          }

          &__buy.product__buy-phone {
            border-bottom: 0;
            padding-bottom: 30px;
            padding-top: 0;

            .product__buy__top {
              display: none;
            }

            .product__buy__bottom {
              padding: 20px 20px 0 20px;
              border-top: 0;

              button, a {
                width: 100%;
                margin-right: 0;

                &:first-child {
                  margin-bottom: 10px;
                }
              }
            }
          }


          &__code {
            padding: 20px 20px 0 20px;
            width: 100%;
            background: #FFFFFF;
            margin-bottom: 0;
            padding-bottom: 5px;

            p {
              margin-bottom: 0;
            }
          }

          &__timer {
            padding: 0;
            margin-top: 0;
          }

          &__buy {
            &__top {
              &__right {
                display: none;
              }
            }
          }
        }

        .delivery-payment {
          padding: 0 20px;
          flex-direction: column;
          margin-bottom: 40px;

          display: none;

          .column {
            width: 100%;
            margin: 0;
            border-bottom: 0;

            &:nth-child(1) {
              padding-top: 30px;
              padding-bottom: 10px;
              border-top: 0;
              border-bottom: 0;
            }

            &__string {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 10px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.14);
              margin-bottom: 10px;

              &:last-child {
                border-bottom: 0;
              }
            }
          }
        }

        .delivery-payment-mobile {
          padding: 0 20px;
          flex-direction: column;
          margin-bottom: 0;
          display: block;

          &.payment {
            margin-bottom: 40px;
          }

          &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 42px 0 18px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.14);
            cursor: pointer;

            &:before {
              content: none;
            }

            p {
              font-size: 22px;
            }

            span {
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid rgba(0, 0, 0, 0.14);
              border-radius: 50%;

              svg {
                transition: 0.28s transform;
              }
            }
          }

          &__body {
            .column {
              width: 100%;
              margin: 0;
              border-bottom: 0;
              padding: 20px 0;
            }

            .column__string {
              display: flex;
              align-items: center;
              //justify-content: space-between;
              margin-bottom: 6px;
              padding-bottom: 10px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.14);

              &:last-child {
                border-bottom: none;
              }

              p {
                margin-bottom: 0;
              }
            }

            .column__tooltip {
              display: flex;
              align-items: center;
              width: 50%;

              p {
                width: 80%;
              }
            }

            .icon:hover .discount {
              opacity: 1;
              visibility: visible;
            }

            .discount {
              padding: 15px;
              background: #1F1F1F;
              border-radius: 6px;
              opacity: 0;
              visibility: hidden;
              transition: 0.28s visibility, 0.28s opacity;
              z-index: 10;
              pointer-events: none;

              p {
                color: #FFFFFF;
                font-family: 'Geometria', serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 120%;
                margin-bottom: 0;
                width: 230px;
                letter-spacing: normal;
              }
            }
          }
        }

        .delivery-payment-mobile.payment {
          .delivery-payment-mobile__body {
            .column__tooltip {
              display: flex;
              align-items: center;
              width: 90%;
            }
          }

        }

        .characteristics__header p, .description__header p, .additional-materials__header p, .applications__header p, .applications__title p {
          font-size: 22px;
        }

        .advantages {
          flex-direction: column;
          padding: 20px;
          //border-radius: 0;
          margin-bottom: 40px;

          .advantage {
            width: 100%;
          }
        }

        .characteristics, .additional-materials, .description, .applications {
          padding: 0 20px;
          margin-bottom: 40px;
        }

        .reviews {
          margin-bottom: 60px;

          #comment-list-holder {
            border-radius: 0;

            .reviews__header {
              padding: 20px;

              &__left {
                > p {
                  font-size: 22px;
                  margin-bottom: 2px;
                }

                .wrap-star {
                  margin-right: 0;
                }
              }
            }

            .reviews__body {
              .review-block-item {
                padding: 0 20px;

                &__avatar {
                  .line {
                    height: calc(100% - 50px);
                    position: absolute;
                    width: 3px;
                    top: 50px;
                    left: 40px;
                  }
                }
              }
            }

            .load-more {
              margin: 30px 20px 0 20px;
              width: calc(100% - 40px);
            }

            .reviews__no-comments {
              .description {
                &__icon {
                  ul.stars {
                    li {
                      span {
                        width: 40px;
                        height: 40px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }

      .product__image {
        .product__slider-nav {
          .slick-track {
            .slick-slide {
              //height: 46px;
              img {
                min-width: unset;
              }
            }
          }
        }
      }
    }

    .wrap-width {
      padding: 0;

      .wrap-slider-rec-products {
        margin-bottom: 60px;
        border-radius: 0;

        &__header {
          padding: 20px;

          p {
            font-size: 22px;
            text-align: left;
          }

          .buttons {
            .wrap-slider-rec-products-next, .wrap-slider-rec-products-prev {
              width: 35px;
              height: 35px;

              svg {
                width: 7px;
              }
            }
          }
        }

        .wrap {
          border-radius: 0;

          .wrap-holder .ttl-text, .wrap-holder h2 {
            padding: 20px;
          }
        }

        .category-links.slider-tovar {
          .holder {
            height: 350px;

            .box-category-holder {
              .box-category {
                .item-category {
                  padding: 10px;

                  .action {
                    //padding: 2px 4px;
                    span {
                      //width: 4px;
                      //height: 4px;
                      margin-right: 4px;
                    }

                    p {
                      //font-size: 9px;
                    }
                  }

                  .image-holder {
                    height: 140px;
                  }

                  .description-category {
                    .compare-block {
                      display: none;
                    }

                    .wishlist-block {
                      display: none;
                    }

                    .wrap-star {
                      margin-bottom: 0;
                    }

                    > .price-new.pull-left {
                      text-align: left;
                      padding: 0;
                    }

                    > .price-new {
                      letter-spacing: -0.03em;
                      color: #1F1F1F;
                      font-weight: 500;
                      font-size: 26px;
                      line-height: 75%;
                      font-family: "Geometria", serif;
                      font-weight: 500;
                      padding: 0 10px;

                      .old-price-holder {
                        height: 20px;
                        margin-bottom: 5px;

                        .old-price {
                          display: inline-block;
                          margin-top: 0;
                          text-decoration: none;
                          font-size: 16px;
                          line-height: 100%;
                          letter-spacing: -0.01em;
                          color: #9C9C9C;
                          position: relative;

                          .discount {
                            position: absolute;
                            left: calc(100% + 5px);
                            font-size: 10px;
                            line-height: 120%;
                            letter-spacing: -0.03em;
                            color: #1F1F1F;
                            flex: none;
                            order: 0;
                            flex-grow: 0;
                            background: linear-gradient(0deg, #E3F5E9, #E3F5E9), linear-gradient(0deg, #E5E8FC, #E5E8FC), #E6DFFB;
                            border-radius: 8px;
                            padding: 2px 5px;
                          }
                        }
                      }
                    }

                    .block-buy {
                      .block-buy {
                        .price-new {
                          display: none;
                        }

                        .pull-right.btn-holder {
                          display: flex;
                          align-items: center;
                          position: relative;
                          margin: 0 -3px;

                          .wishlist-block, .compare-block {
                            position: static;
                            display: block;
                            margin: 0 3px;
                          }

                          .compare-block {
                            > a:nth-child(2) {
                              width: max-content;
                              height: max-content;
                            }
                          }

                          a {
                            display: none;
                            width: 36px;
                            height: 36px;
                            padding: 0;
                            align-items: center;
                            border-radius: 10px;
                            justify-content: center;
                            margin: 0 3px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }
  .product__buy__top__left {
    flex-direction: row !important;
    align-items: flex-end;
    justify-content: flex-end;


  }
  .product__buy__old-price {
    margin-left: 10px;
  }
}

.to-tablet {
  display: none !important;
}

@media screen and (max-width: 992px) {
  div.scroll-to-top a {
    bottom: 135px;
    right: 30px;
  }
}

._orientationRight_de3d .button_7f18 {
  margin-right: 20px;
  margin-bottom: 60px !important;
}

.main-slider-home, .article-slider {
  .action {
    display: flex;
    align-items: center;
    padding: 3px 8px;
    background: linear-gradient(0deg, #FD992F, #FD992F),
    linear-gradient(0deg, #FFAB1A, #FFAB1A), #FF832B;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    left: 10px;

    span {
      background: #FFFFFF;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-right: 8px;
    }

    p {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.01em;
      margin-bottom: 0;
      color: #fff;
    }
  }

  .price-new.pull-left {
    .old-price-holder {
      .discount {
        display: none !important;
      }
    }
  }

  .clearfix.block-buy {
    .clearfix.block-buy {
      display: flex;
      align-items: center;

      .price-new.pull-left {
        flex-shrink: 0;
        margin-right: 10px;

      }

      .pull-right.btn-holder {
        margin-left: auto;

        a {
          min-width: unset;
          height: auto;
          flex-grow: 1;
          display: flex !important;
          justify-content: center;
          align-items: center;


          p {
            display: none;
          }

          @media screen and (max-width: 768px) {
            flex-grow: 0;
            min-width: 44px;
            max-width: 130px;
            min-height: 44px;
          }
        }
      }

    }
  }

  .price-new.pull-left.to-tablet {
    display: none !important;
  }

  .wishlist-block, .compare-block {
    display: none !important;
  }

  .in-cart-block {
    display: none !important;
  }
}

.checkout-wrapper {
  font-family: "Geometria", serif;
  font-weight: 400;
}

.basket .present-product .basket-holder .basket-item .basket-item__price {
  font-size: 24px;
  color: #333;
  flex-grow: 1;
  flex-shrink: 0;
}

#city_retail_result {
  top: 100%;
  max-width: 283px;
  left: 15px;
}

.checkout-wrapper .form-wrapper .FormHolder .delivery-method .checkbox .delivery-wrap .form-height label {
  pointer-events: none;
}

.with-result .result span {
  .highlighted {
    display: inline-block;
    padding: 0;
    background: #17627e;
    color: #fff;
  }
}

.main {
  .product-page {
    .category_list_page {
      height: auto !important;
    }
  }
}
.product-list-page .slider-poduct .slick-next {
  right: 0;
}
.main {
  .search-slider-button {
    .slick-prev {
      top: 50%;
    }
    .slick-next {
      top: 50%;
    }
  }
}

.basket .present-product .basket-holder .basket-item .basket-item__image {
  max-width: 100px;

  img {
    width: 100%;
    object-fit: cover;
  }
}

//.added-to-cart__controls {
//    .label{
//        top: 0;
//        left: 0;
//    }
//}
.added-to-cart {
  .modal-u__body {
    padding: 30px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      width: calc(100% + 60px);
      bottom: 0;
      left: -30px;
      position: absolute;
      height: 1px;
      background: rgba(0, 0, 0, 0.14);
    }

    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      margin-bottom: 0;
      font-family: 'Geometria', serif;
    }

    .desktop-controls {
      margin: 0 auto;
      max-width: 10%;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .close {
      width: 45px;
      height: 45px;
      background: #F8F8F8;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
    }
  }

  .added-to-cart__body-wrapper {
    position: relative;

    .background {
      position: absolute;
      height: 35px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 63.02%);
      width: calc(100% - 20px);
      bottom: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: 0.28s opacity, 0.28s visibility;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__body {
    //margin: 30px 0;
    max-height: 340px;
    @media screen and (max-width: 768px) {
      min-height: 320px;
    }
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    &::-webkit-scrollbar-track {
      background-color: #FFFFFF;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #FFFFFF;
    }

    &::-webkit-scrollbar-thumb {
      background: #D0D0D0;
      border-radius: 10px;
    }


    .added-to-cart__product {
      display: flex;
      margin-top: 20px;
      width: 390px;
      margin-bottom: 30px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &:last-child {
        margin-bottom: 20px;
      }

      &__image {
        max-width: 78px;
        margin-right: 20px;
        height: 78px;

        img {
          width: 100%;
        }
      }

      &__description {
        max-width: 239px;
        flex-grow: 1;
        margin-right: 5px;

        p {
          text-align: left;
        }

        @media screen and(max-width: 768px) {
          width: auto !important;
        }

        a {
          display: block;
          font-family: "Geometria", serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          color: #1F1F1F;
          text-align: left;
          margin-bottom: 9px;
        }
      }

      &__price {
        display: flex;
        align-items: center;

        &-count {
          display: flex;
          max-width: 115px;
          flex-grow: 1;
          align-items: center;
          border: 1px solid rgba(0, 0, 0, 0.14);
          box-sizing: border-box;
          border-radius: 10px;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 33px;
            background: transparent;
            flex-grow: 0;
            transition: 0.28s background-color;

            &:hover {
              background-color: #F8F8F8;
            }
          }

          p.count {
            margin-bottom: 0;
            font-family: "Geometria", serif;
            font-weight: 700;
            text-align: center;
            flex-shrink: 0;
          }
        }

        .price {
          margin-bottom: 0;
          text-align: left;
          margin-left: 15px;
          font-family: "Geometria", serif;
          font-weight: 500;
          font-size: 16px;
        }
      }

      &__remove {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 8px;
        transition: 0.28s background-color;
        flex-shrink: 0;

        &:hover {
          background-color: #F8F8F8;
        }
      }
    }
  }

  &__total {
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.14);
    padding-top: 11px;
    margin-bottom: 20px;

    p {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: #494949;
      margin-bottom: 0;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      flex-grow: 1;
      padding: 0 10px;
      height: 44px;
      border: 0;

      &.cancel {
        max-width: 92px;
        background: #F8F8F8;
        color: #1F1F1F;
        margin-right: 15px !important;

      }
    }
  }
}

//jdiv,jdiv *{
//    z-index: 100 !important;
//}
.header__basket-empty {
  width: 290px;
  margin: auto;
  height: 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  svg {
    width: 140px !important;
    height: 140px !important;
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }
}

.checkout-wrapper .form-wrapper .FormHolder .type-authorization-wrap .forgot-password {
  background: unset;
  border: unset;

}

#for_liqpay, .checkout-wrapper .product-checkout .checkout-wrapper__confirm .confirm-btn {
  transition: .25s;

  &:disabled {
    background: #17627e70;
    transition: .25s;
  }
}


@media screen and (max-width: 380px) {
  .wrap-holder .slick-next {
    right: -20px !important;
  }
  .wrap-holder .slick-prev {
    left: -20px !important;
  }

  .breadcrumb-holder {
    height: 45px;
    width: auto;
    display: flex;
    white-space: nowrap;
    overflow: auto;
    padding: 0;

    .breadcrumb {
      padding: 10px 10px 5px 10px;
      //&::-webkit-scrollbar-track{
      //    margin-left: -5px;
      //}

      &::-webkit-scrollbar {
        width: 3px;
        background-color: rgba(255, 0, 0, 0);
        height: 3px;
        border-radius: 50px;
        margin-left: 5px;
      }

      &::-webkit-scrollbar-thumb {
        margin-top: 5px;
        background-color: #b7b7b7;
        border-radius: 15px;
      }
    }
  }

}


///HOME PAGE


.one {
  background: #fff;
  border-radius: 20px;
}

.catalog-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 15px;
  margin-bottom: 101px;
}

.top-box {
  display: grid;
  grid-template-rows: 1fr;
  //grid-row-gap: 15px;
}

.middle-box-wrapper {
  display: grid;
  //grid-template-rows: repeat(4, 2fr);
  grid-column-gap: 16px;
  grid-row-gap: 15px;
  //width: 662px;
}

.catalog-box1 {
  //display: grid;
  //grid-column-start: 1;
  //grid-row-start: 1;
  //grid-row-end: 7;
  //height: 631px;
  padding: 30px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.catalog-box2 {
  //grid-column-start: 1;
  //grid-row-start: 1;
  //grid-row-end: 3;
}

.catalog-box3 {
  //grid-column-start: 2;
  //grid-row-start: 1;
  //grid-row-end: 3;
}

.catalog-box4 {
  //grid-column-start: 1;
  //grid-row-start: 3;
  //grid-row-end: 7;
}

.catalog-box5 {
  //grid-column-start: 2;
  //grid-row-start: 3;
  //grid-row-end: 7;
}

//.small-box-wrapper {
//    display: grid;
//    grid-column-gap: 16px;
//    grid-row-gap: 15px;
//    grid-template-rows: repeat(5, 1fr);
//}
.popular-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 100px;

  &__title {
    margin-bottom: 23px;
  }

  &__categories {
    overflow-x: scroll;
    display: flex;
    width: 100%;

    &::-webkit-scrollbar-track {
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 50px;
      background-color: #E5E5E5;
      height: 4px;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #44BB6E;
      height: 10px !important;
    }

    &::-webkit-scrollbar:hover {
      width: 50px;
      height: 50px;
    }
  }

  &__category-wrapper {
    display: flex;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 30px;
    }
  }

  &__small-box {
    width: 329px;
    padding: 20px;
    background: #fff;
    border-radius: 20px;
    margin-right: 17px;
    //margin-bottom: 15px;
  }
}


.additional-box-wrapper {
  display: grid;
  //grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-column: 1/-1;
  padding: 0;
  list-style: none;
}

.additional-box-wrapper .additional-box {
  background: #fff;
  text-align: center;
}

/* three items */
.additional-box:first-child:nth-last-child(2),
.additional-box:first-child:nth-last-child(2) ~ .additional-box {
  grid-row: 1/2;
}

/* three items */
.additional-box:first-child:nth-last-child(3),
.additional-box:first-child:nth-last-child(3) ~ .additional-box {
  grid-row: 1/3;
}

/* four items */
.additional-box:first-child:nth-last-child(4),
.additional-box:first-child:nth-last-child(4) ~ .additional-box {
  grid-row: 1/4;
}


.catalog-top {
  &__background-wrapper {
    position: relative;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  }

  &__image {
    width: 170px;
    height: 170px;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #1F1F1F;
    text-align: center;
  }

  &__subtitle {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #9C9C9C;
  }

  &__links {
    margin-top: 20px;
    height: 100%;
    margin-bottom: 23px;
    //overflow: hidden;
    //overflow-y: scroll;
    //&::-webkit-scrollbar-track {
    //    background-color: #FFFFFF;
    //}
    //
    //&::-webkit-scrollbar {
    //    width: 3px;
    //    background-color: #FFFFFF;
    //}
    //
    //&::-webkit-scrollbar-thumb {
    //    background: #D0D0D0;
    //    border-radius: 10px;
    //}
    &-wrapper {
      li {
        list-style: none;
        margin-bottom: 5px;
      }

      a {
        font-family: "Geometria", serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.01em;
        color: #494949;
      }
    }
  }

  &__button {

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      background: #F8F8F8;
      border-radius: 6px;
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.01em;
      border: 0;
      height: 35px;
      width: 100%;
      outline: none;
      padding: 0;
      color: #1F1F1F;

      &:hover {
        background-color: #EFEFEF;
      }

      &.active {
        border: 2px solid #44BB6E;
        background-color: #E5E5E5;

        span {
          color: #44BB6E;
        }
      }

      &:focus {
        opacity: 0.8;
        border: 2px solid #1F1F1F;
        background: #F8F8F8;
        color: #1F1F1F;
      }

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.middle-box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //margin-bottom: 15px;
  &:nth-child(-n+2) {
    //margin-bottom: 7px;
  }

  &:nth-last-child(-n+2) {
    //margin-top: 7px;
  }

  &__image {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    min-height: 114px;

    &-wrapper {
      width: 80px;
      height: 80px;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 65%;
  }

  &__title {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #1F1F1F;
  }

  &__subtitle {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #9C9C9C;
  }

  &__link {
    li {
      list-style: none;
      margin-bottom: 7px;
    }

    a {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: #494949;
    }
  }

  &__button {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      background: #F8F8F8;
      border-radius: 6px;
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.01em;
      border: 0;
      width: 100%;
      height: 35px;
      outline: none;
      padding: 0;
      color: #1F1F1F;

      &:hover {
        background-color: #EFEFEF;
      }

      &.active {
        border: 2px solid #44BB6E;
        background-color: #E5E5E5;

        span {
          color: #44BB6E;
        }
      }

      &:focus {
        opacity: 0.8;
        border: 2px solid #1F1F1F;
        background: #F8F8F8;
        color: #1F1F1F;
      }

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.small-box {
  padding: 20px;

  &__image {
    display: flex;
    align-items: center;
    //margin-bottom: 10px;
    &-wrapper {
      width: 74px;
      height: 74px;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 65%;
  }

  &__title {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: #1F1F1F;
  }

  &__subtitle {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #9C9C9C;
  }
}

.additional-box {
  padding: 20px;

  &__image {
    display: flex;
    align-items: center;
    //margin-bottom: 10px;
    &-wrapper {
      width: 74px;
      height: 74px;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 65%;
  }

  &__title {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: #1F1F1F;
    text-align: center;
  }

  &__subtitle {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #9C9C9C;
  }
}

.small-box-tablet-wrapper {
  display: none;
}


.background-gradient {
  width: 100%;
  height: 52px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 78.12%, #FFFFFF 100%);
  position: absolute;
  bottom: 0;
}


@media only screen and (max-width: 1000px) {
  .catalog-wrapper.container-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .catalog-wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .top-box {
    width: 358px;
    height: 470px;
  }

  .catalog-top {
    &__link {
      flex-direction: unset;
      justify-content: end;
      padding-bottom: 20px;
    }

    &__links-wrapper {
      width: 298px;

      ul {
        overflow: hidden;
      }

      a {
        width: 100%;
        display: block;
        white-space: nowrap;
      }
    }

    &__image {
      width: 80px;
      height: 80px;
      margin-bottom: 0;
      margin-right: 15px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }

  .middle-box {
    padding: 10px 10px;
    height: 230px;

    &-wrapper {
      //width: 360px;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }

    &__title {
      text-align: center;
      min-height: 72px;
    }

    &__link {
      display: none;
    }

    &__button {
      display: none;
    }

    &__background-wrapper {
      display: none;
    }

    &__image-wrapper {
      margin-right: 0;
      margin-bottom: 5px;
    }

    &__image {
      margin-bottom: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title-wrapper {
      align-items: center;
    }
  }
  .small-box-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1/-1;
    grid-template-rows: repeat(3, 1fr);
  }
  .additional-box-wrapper {
    display: none;
  }

}

@media only screen and (max-width: 767px) {

  .middle-box-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .catalog-wrapper {
    grid-template-columns: 1fr;
  }

  .sliderContainer {
    //padding-left: 10px;
    //padding-right: 10px;
  }

  .top-box {
    width: 100%;
    height: unset;
  }
  .catalog-top {
    &__image {
      width: 60px;
      height: 60px;
      margin-bottom: 0;
      margin-right: 15px;

      img {

      }
    }

    &__subtitle {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #9C9C9C;
    }

    &__link {
      flex-direction: row;
      padding-bottom: 0;
      border: unset;
      justify-content: flex-start;
    }

    &__links-wrapper {
      width: 220px;
    }

    &__text {
      display: flex;
      flex-direction: column;
    }

    &__links {
      height: 137px;
      margin-top: 15px;
      margin-bottom: 5px;

      overflow: hidden;
      overflow-y: scroll;
    }

    &__links-wrapper {
      li {
        margin-bottom: 7px;
      }

      a {
        font-size: 14px;
        line-height: 120%;
        color: #494949;
      }
    }
  }
  .middle-box {
    padding: 25px 10px;
    height: unset;

    &:nth-child(-n+2) {
      //margin-bottom: 5px;
    }

    &:nth-last-child(-n+2) {
      //margin-top: 5px;
    }

    &-wrapper {
      //width: 280px;
    }

    &__image-wrapper {
      width: 70px;
      height: 70px;
    }

    &__title {
      font-size: 16px;
      line-height: 140%;
      min-height: unset;
    }

    &__subtitle {
      font-size: 12px;
      line-height: 110%;
    }
  }
  //.small-box{
  //    &-wrapper {
  //        grid-template-columns: repeat(1, 1fr);
  //        grid-row-gap: 10px;
  //    }
  //    &__image-wrapper{
  //        width: 60px;
  //        height: 60px;
  //    }
  //    &__title {
  //        line-height: 140%;
  //    }
  //    &__subtitle {
  //        font-size: 12px;
  //        line-height: 110%;
  //    }
  //}

  .product-relation{
    margin-top: 40px;
    border-radius: 8px;
    min-height: 325px;
    &__card{
      &-image {
        img {
          width: 115px;
          height: 115px;
        }
      }
      &-image{
        padding: 5px;
      }
      &-wrapper {
        padding: 5px;
        &-title {
          font-size: 11px;
          span {
            height: 30px;
          }
        }
        &-price {
          align-items: unset;
          width: 100%;
          flex-direction: column;
          height: 70px;
        }
        &-button{
          width: 100%;
          button {
            padding: 5px 5px !important;
            font-size: 11px;
          }
        }
      }
      &-price-new {
        font-size: 18px;
      }
      &-action {
        top: 6px;
        p{
          font-size: 10px;
        }
      }
    }
  }
}


//STOCK

.stock__arrow-button-left,
.stock__arrow-button-right {
  border: none;
  background: unset;
  top: -50px;

  &:focus {
    outline: none;
  }
}

.stock__arrow-button-right {
  position: absolute;
  right: 0;
}

.stock__arrow-button-left {
  position: absolute;
  right: 40px;
}

.card-product-page {
  font-family: "Geometria", serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  margin-bottom: 0;
}

.blog-title {
  text-align: left;
  font-family: "Geometria", serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  margin-bottom: 0;
}

.stock__slider-wrapper .slick-list {
  margin: 0 -12px;
}

.stock__slider-wrapper .slick-dots {
  position: static;
  padding-top: 30px;
  padding-bottom: 20px;

  li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #E5E5E5;

    &.slick-active {
      background: #44BB6E;

      button:before {
        content: unset;
      }
    }

    button:before {
      content: unset;
    }
  }
}

.stock {

  &__link {
    display: block;
    font-family: "Geometria", serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #287C9B;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  }

  &__slider-wrapper {
    padding-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.14);
    border-bottom: none;
  }

  &__slider-item-category {
    background: #fff;
    border-radius: 20px;
    max-width: 446px;
    padding: 20px;
    margin: 0 12px;
  }

  &__slider-image {
    max-width: 406px;
    height: 234px;
    border-radius: 12px;
    margin-bottom: 15px;

    a {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
    }

  }

  &__slider-category {
    //margin: 0 7px;
  }

  &__title {
    text-align: left;
    margin-bottom: 25px;

    a {
      p {
        font-weight: bold;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: -0.01em;
        color: #1F1F1F;
        margin-bottom: 5px;
      }

      span {
        font-family: "Geometria", serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.01em;
        color: #727272;
        margin-bottom: 0;
      }
    }
  }

  &__end {
    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        font-family: "Geometria", serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.01em;
        color: #1F1F1F;
        padding: 10px 15px;
        border: 2px solid #1F1F1F;
        border-radius: 6px;
        transition: .25s;

        &:hover {
          background: #F8F8F8;
          transition: .25s;
        }

        &:active {
          background: #EFEFEF;
          transition: .25s;
        }
      }
    }

  }

  &__end-counter {
    display: flex;
    align-items: center;

    p {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.01em;
      color: #1F1F1F;
      margin-bottom: 0;
      margin-right: 10px;

    }

    span {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      letter-spacing: -0.01em;
      color: #1F1F1F;
      padding: 8px 14px;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
      display: block;
    }
  }
}


//COUNTER BLOCK

.counter-text {
  width: 100%;
  height: 100%;

  text {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 44px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #44BB6E;
    display: block;
  }

  .text-cont {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #1F1F1F;
  }
}

.progress-bar-block .text-cont {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #1F1F1F;
}

.progress-bar-block.advantage-block__wrapper .column {
  width: 25%;
}

.progress-bar-block.advantage-block__wrapper {
  margin-bottom: 100px;
  max-width: 1367px;
}

.advantage-block {
  &__text {
    text {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 44px;
      line-height: 120%;
      text-align: center;
      letter-spacing: -0.01em;
    }

    i {
      display: block;
      width: 181px;
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      letter-spacing: -0.01em;
      color: #1F1F1F;
      margin: 0 auto;
    }
  }

  &__image {
    margin-bottom: 20px;

    img {
      width: 103px;
      height: 114px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .container-wrapper.slider-container {
    //padding: 0 20px;
  }
  .stock {
    &__slider-item-category {
      background: #fff;
      border-radius: 20px;
      max-width: 446px;
      padding: 20px;
      margin: 0 3px;
    }

    &__end-counter {
      p {
        display: none;
      }
    }

    &__slider-wrapper .slick-dots {
      bottom: -35px;
    }

    &__slider-wrapper {
      padding-top: 20px;

      .slick-list {
        margin: 0 auto;
      }
    }

    &__slider-image {
      max-width: 321px;
      height: 185px;

      a {
        img {
          object-fit: contain;
        }
      }
    }

    &__end-counter {
      span {
        line-height: 111%;
      }
    }

    &__end-wrapper {
      a {
        line-height: 83%;
      }
    }

  }
  .progress-bar-block.advantage-block__wrapper .column {
    width: 50%;
  }

  .counter-text {
    display: flex;
    align-items: center;

    text {
      font-size: 34px;
      text-align: left;
    }

    i {
      font-size: 16px;
      text-align: left;
    }
  }
  .advantage-block {
    &__text {
      text-align: left;

    }

    &__image {
      img {
        width: 110px;
      }
    }
  }
  .container-wrapper.slider-container.popular_products-wrapper {
    padding: 0 20px;
  }

  .container-wrapper.slider-container.new-items-wrapper {
    padding: 0 20px;
  }

  .new-items-wrapper {
    .box-category-holder {
      .box-category:hover .block-addition {
        display: none;
      }
    }

    .wrap-slider-rec-products {
      .category-links.slider-tovar {
        .holder {
          //height: 487px;
        }
      }
    }
  }

}

@media only screen and (max-width: 767px) {

  .container-wrapper.slider-container.popular_products-wrapper {
    padding: 0 20px;
  }

  .container-wrapper.slider-container.new-items-wrapper {
    padding: 0;
  }

  .progress-bar-block.advantage-block__wrapper .column {
    width: 100%;
  }
  .counter-text text {
    font-size: 22px;
  }
}

@media only screen and (max-width: 375px) {
  .container-wrapper.slider-container.popular_products-wrapper {
    padding: 0 20px;
  }

  .container-wrapper.slider-container.new-items-wrapper {
    //padding: 0 20px;
  }
}


//NEWS

.wrap-holder.wrap-slider-rec-products.new-items-wrap {
  max-width: unset;
}

.new-items__buttons {
  button {
    width: 35px;
    height: 35px;
  }
}

.new-item__button-wrapper {

  display: flex;
  justify-content: space-between;
  padding: 30px 30px 25px 20px;
  align-items: center;

  p {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #1F1F1F;
    margin-bottom: 0;
  }
}

.new-items__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    display: block;
    width: 44px;
    height: 44px;
    background: #F8F8F8;
    border-radius: 8px;
    border: 0;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}


.new-items {
  &-wrapper {
    //max-width: 1400px;
    //margin: 0 auto;
  }

  .category-links {
    .holder {
      .box-category-holder {
        .box-category {

        }
      }
    }
  }

  .substrate {
    height: 71px !important;
  }

  &__block-addition {
    position: unset !important;
    border-top: none;
    padding: 0;
    border-radius: 0;
    background: #fff;
    box-shadow: unset;
    margin-bottom: 20px;

    ul {
      text-align: left;

      li {
        span {
          margin-bottom: 0;
          font-size: 14px;
          line-height: 120%;
          letter-spacing: -0.01em;
          color: #1F1F1F;
        }

        p {
          font-size: 14px;
          line-height: 120%;
          letter-spacing: -0.01em;
          color: #494949;
          margin-bottom: 0;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba(0, 0, 0, 0.14);
        padding-top: 7px;
        margin-bottom: 2px;

        a {
          display: flex;
          justify-content: space-between;
          width: 100%;

          span {
            color: #44BB6E;
          }
        }

        select {
          max-width: 100px !important;
          display: flex;
          align-items: center;
          border: unset;
          height: 16px;
          padding: 0;
          background: url(../images/select-arrow-down.png) 96% 1px no-repeat;

          &:focus {
            border: unset;
          }
        }
      }
    }
  }

  &.block-addition__text {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #1F1F1F;
  }

  &__wrapper {
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    background: #fff;

    h1 {
      padding-bottom: 18px;
      border-bottom: 1px solid #E5E5E5;
      margin: 0 30px;
    }
  }

  &__box-category {
    position: relative;

    ul.stars {
      display: flex;
      align-items: center;

      li {
        margin: 0 2px;

        span {
          width: 13px;
          height: 13px;

          &.star1 {
            background: url('/assets/images/star-full.svg');
            background-size: cover;
          }

          &.star2 {
            background: url('/assets/images/star-almost-full.svg');
            background-size: cover;
          }

          &.star3 {
            background: url('/assets/images/star-almost-empty.svg');
            background-size: cover;
          }

          &.star4 {
            background: url('/assets/images/star-empty.svg');
            background-size: cover;
          }

          &.star5 {
            background: url('/assets/images/star-half.svg');
            background-size: cover;
          }
        }
      }
    }
  }

  &__stars {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    span {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      letter-spacing: -0.03em;
      color: #1F1F1F;
    }
  }

  &__box-category-holder {
    padding: 20px;
    border-right: 1px solid #E5E5E5;
    min-height: 487px;

    &:nth-child(4n+4) {
      border-right: none;
    }
  }

  &__action {
    p {
      background: #FC2D00;
      border-radius: 20px;
      width: 80px;
      color: #fff;
      padding: 2px 6px 2px 16px;
      position: relative;
      font-family: "Geometria", serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.01em;
      margin-bottom: 6px;

      &:after {
        position: absolute;
        content: '';
        background: #fff;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__image-holder {
    width: 248px;
    height: 238px;
    margin: 0 auto 29px;

    a {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__about-title {
    margin-bottom: 6px;

    a {
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.01em;
      color: #1F1F1F;
      font-family: "Geometria", serif;
      font-weight: 400;
    }
  }


  &__buy-button {
    display: flex;
    flex-direction: column;
    width: 36px;
    position: absolute;
    right: 5px;
    top: 26px;

    a.active {
      width: 36px;
      height: 36px;
      //border: 2px solid #44BB6E;
      box-shadow: inset 0 0 0 2px #44bb6e;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      margin-bottom: 5px;
      margin-right: 5px;

      svg {
        width: 16px;
        stroke: #44BB6E;
      }
    }

    button {
      border: unset;
      background: #F8F8F8;
      border-radius: 10px;
      padding: 8px;
      margin-bottom: 5px;
      transition: .25s;
      width: 36px;
      height: 36px;

      svg {
        width: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: #EFEFEF;
        transition: .25s;
      }

      &.active {
        box-shadow: inset 0 0 0 2px #44bb6e;
        transition: .25s;

        svg {
          width: 17px;

        }

        span {
          color: #44BB6E;
        }
      }

      &:focus {
        //opacity: 0.8;
        //border: 2px solid #1F1F1F;
        //background: #F8F8F8;
        //color: #1F1F1F;
        //transition: .25s;
      }
    }
  }

  &__block-addition {
    margin-top: 20px;
    margin-bottom: 0;
  }

  &__block-buy {
    display: flex;
    justify-content: space-between;
  }

  &__add-to-cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    max-width: 135px;
    min-height: 44px;

    margin: 0;

    svg {
      margin-right: 15px;
    }

  }

  &__price-new {
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 26px;
    line-height: 75%;
    letter-spacing: -0.03em;
    color: #1F1F1F;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-dots {
    position: static;
    padding-top: 30px;
    padding-bottom: 20px;

    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #E5E5E5;

      &.slick-active {
        background: #44BB6E;

        button:before {
          content: unset;
        }
      }

      button:before {
        content: unset;
      }
    }
  }

  .slick-list {
    //overflow: unset;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .item-category {
    position: relative;

    &:hover {
      //box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    }
  }

  .descr {
    display: none;
    padding: 10px;
    margin-top: 17px;
    background: #fff;
    height: 200px;
    position: absolute;
    bottom: -203px;
    transition: .25s;
    //-moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    //-webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    //box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    ul {
      li {
        height: 100%;
        font-size: 20px;
        font-family: "Geometria", serif;
        font-weight: 500;
      }
    }
  }

  .box-category-holder:hover .descr {
    display: block;
    //position: absolute;
    z-index: 10;
    width: 100%;
    bottom: -100px;
  }

  &__category-wrap {
    //overflow-x: hidden;
  }
}

@media only screen and (max-width: 1000px) {
  .box-category-holder .box-category:hover .block-addition.new-items__block-addition {
    display: none;
  }

  .wrap-slider-rec-products .new-items__category-wrap .new-items {
    .holder {
      min-width: unset;
      height: 400px;
    }
  }
  &.card-product-page .wrap-holder.wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .description-category .block-buy .btn-buy {
    width: 44px;
    height: 44px;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #44BB6E;
    position: relative;
  }
  .popular__button-mobile-link {
    //border-radius: 10px;

    .popular__button-mobile-added {
      background-color: transparent;
      position: relative;
      border: 2px solid #44BB6E;
      border-radius: 8px;
      padding: 13px;

      span {
        position: absolute;
        top: -7px;
        right: -7px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .new-items__buttons {
    button {
      width: 35px;
      height: 35px;

      svg {
        width: 7px;
        height: 12px;
      }
    }
  }
  .new-item__button-wrapper {
    padding: 30px 30px 25px 40px;

    p {
      font-size: 22px;
    }
  }
  .wrap-holder.wrap-slider-rec-products .wrap.new-items__category-wrap {
    width: calc(100% + 40px);
    margin: 0 -20px;
    border-radius: 0;

    .category-links {
      min-height: unset;
    }

    .slick-track {
      margin-right: 10px;
      margin-left: 20px;
    }

    .slick-dots {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }
  .wrap-slider-rec-products .new-items__category-wrap .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .description-category .block-buy .price-new {
    display: none;
  }
  .product-page.card-product-page .wrap-holder.wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .description-category .block-buy .btn-buy {
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 10px;
    border: 2px solid #44BB6E;
    position: relative;
  }
  .popular__button-mobile-link {
    //border-radius: 10px;

    .popular__button-mobile-added {
      background-color: transparent;

      span {
        position: absolute;
        top: -7px;
        right: -7px;
      }
    }
  }

  .wrap-slider-rec-products .new-items__category-wrap .new-items .holder {
    height: unset;
  }
  .new-items {
    //padding-left: 10px !important;
    //padding-right: 10px !important;
    .price-new {
      display: none;
    }

    &__buy-button {
      button {
        padding: 6px;

        svg {
          width: 21px;
        }

        width: 36px;
        height: 36px;
        margin-bottom: 0;
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          svg {
            width: 23px;
          }
        }

      }

      a {
        &.active {
          svg {
            width: 21px;
          }
        }
      }

    }
  }
  .new-items__buy-button.new-items__buy-button-desktop {
    display: none;
  }
  .new-items__buy-button.new-items__buy-button-mobile {
    position: unset;
    flex-direction: unset;
    width: unset;
    height: 36px;
  }
  .wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .description-category .block-buy .price-new.test6767 {
    display: none;
  }
  .stock__arrow-button-left, .stock__arrow-button-right {
    top: -52px;
  }
}

//BLOG SLIDER

.social-holder {
  margin: 0 auto;
}

.blog-slider {
  border: unset;

  &__slide {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    margin: 0 8px;
    min-height: 396px;
  }

  &__image {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    border-radius: 12px;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      margin: 0 auto;
      border-radius: 12px;
    }
  }

  &__title {
    font-family: "Geometria", serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: #1F1F1F;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 46px;
  }

  &__description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: "Geometria", serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    margin-bottom: 18px;
    color: #727272;
    min-height: 48px;
    position: relative;

  }

  &__bg-gradient {
    position: absolute;
    width: 100%;
    height: 15px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 100%, #FFFFFF 58.33%);
    bottom: 0;
    left: 0;
  }

  &__date {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #9C9C9C;

    svg {
      margin-right: 4px;
    }
  }

  &__date-wrapper {
    display: flex;
    align-items: center;

  }

  &__likes {
    display: flex;
    align-items: center;
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: -0.03em;
    color: #9C9C9C;

    svg {
      margin-right: 4px;
    }
  }

  .slick-list {
    margin: 0 -8px;
    border-radius: 20px;
  }

  .slick-dots {
    position: static;
    padding-top: 30px;
    padding-bottom: 20px;

    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #E5E5E5;

      &.slick-active {
        background: #44BB6E;

        button:before {
          content: unset;
        }
      }

      button:before {
        content: unset;
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  .blog-slider {
    &__slide {
      margin: 0;
      padding: 15px;
    }

    &__image {
      margin-bottom: 15px;

      img {
        width: 250px;
        height: 200px;
      }
    }

    &__title {
      line-height: 140%;
      font-family: "Geometria", sans-serif;
      font-weight: 500;
      font-size: 16px;
    }

    &__description {
      font-size: 12px;
      min-height: 43px;
    }
  }

}

//ABOUT US

.about-us {
  padding-bottom: 108px;

  .description__body {
    img {
      &:nth-of-type(odd) {
        margin-right: 20px;
      }

      //&:nth-child(odd) {
      //    margin-right: 30px;
      //}
      //&:nth-child(even) {
      //    margin-right: 30px;
      //    margin-left: 30px;
      //}
      //&:nth-child(3) {
      //    margin-left: 0;
      //}
    }

    .about-us__text {
      p {
        font-family: "Geometria", serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.01em;
        color: #494949;
        margin-bottom: 30px;
      }

      h2 {
        font-family: "Geometria", serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.01em;
      }

      li {
        font-family: "Geometria", serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.01em;
        color: #494949;
      }
    }
  }

  .description__header:before {
    content: unset;
  }
}

.home {

  &__first-banner {
    display: flex;
    padding-top: 20px;
    //align-items: end;
    margin-bottom: 103px;

  }

  &__slide {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 484px !important;
    border-radius: 20px;
    //background-repeat: no-repeat;

    a {
      width: 100%;
      height: 484px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }

    P {
      width: 396px;
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 36px;
      line-height: 105%;
      text-align: center;
      letter-spacing: -0.03em;
      color: #FFFFFF;
      margin: 0 auto 15px auto;
    }

    span {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.01em;
      color: #FFFFFF;
    }

    &-text {
      //position: absolute;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
    }
  }

  .slick-slide {
    background: #3a8999;
    color: white;
    padding: 20px 0 20px;
    font-size: 30px;
    text-align: center;
  }

  .slick-prev:before,
  .slick-next:before {
    color: black;
  }

  .slick-dots {
    bottom: -30px;
  }

  .slick-slide:nth-child(odd) {
    background: #e84a69;
  }

  &__buttons {
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &-prev {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      background: #F8F8F8;
      border-radius: 8px;
      border: 0;
      margin-left: 20px;
    }

    &-next {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      background: #F8F8F8;
      border-radius: 8px;
      border: 0;
      margin-right: 20px;
    }
  }

}


.sliderContainer {
  position: relative;
  //max-width: 1021px;
  max-width: 74.8%;
  width: 100%;
  margin: 0 0 0 auto;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  //margin: 0 0 40px;
  border-radius: 20px;

  .slick-list {
    border-radius: 20px;
  }
}

.background-gradient__progress-bar {
  display: block;
  opacity: 0;
  background: linear-gradient(
                  180deg, rgba(0, 0, 0, 0) 2.6%, rgba(0, 0, 0, 0.3) 39.58%, rgba(0, 0, 0, 0.6) 83.33%);
  width: 100%;
  height: 47px;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: .25s;
}

.progressBarContainer {
  position: absolute;
  bottom: 20px;
  width: 76%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    margin-bottom: 5px;
    text-align: center;
    color: #fff;
  }
}

.progressBarContainer div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 250px;
  height: 22px;
  padding: 0;
  cursor: pointer;
  margin-right: 5px;
  color: #BEBEBE;
  border-radius: 5px;
}

.progressBarContainer div:last-child {
  margin-right: 0;
}

.progressBarContainer div span.progressBar {
  width: 100%;
  height: 3px;
  background: rgba(190, 190, 190, 0.5);
  display: block;
}

.progressBarContainer div span.progressBar .inProgress {
  background-color: #FFFFFF;
  width: 0;
  height: 3px;
}

@media screen and (min-width: 1200px) and(max-width: 1366px) {
  .sliderContainer {
    //max-width: 840px;
  }
}

@media only screen and (max-width: 1000px) {

  .sliderContainer {
    order: 1;
    width: 100%;
    max-width: unset;
    margin-bottom: 30px;
  }

  .progressBarContainer div {
    width: 120px;
  }
  .background-gradient__progress-bar {
    opacity: 1 !important;
    background: none !important;
  }
  .progressBarContainer {
    p {
      display: none;
    }
  }
  .home {
    &__first-banner {
      padding: 20px 20px 0 20px;
      align-items: end;
      margin-bottom: 83px;
      flex-direction: column;
    }

    &__slide {
      height: 342px !important;
    }
  }
  .catalog {
    &__list-description {
      display: none !important;
    }

    &__list {
      order: 2;
      margin-right: 0;
    }

    &__list-link {
      padding: 0 0 30px 0;
      background-color: unset;
      border-radius: unset;
      overflow-x: scroll;
      width: 730px;

      &::-webkit-scrollbar-track {
        border-radius: 50px;
        background-color: #E5E5E5;
        height: 4px;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #44BB6E;
        height: 10px !important;
      }

      &::-webkit-scrollbar:hover {
        width: 50px;
        height: 50px;
      }
    }

    &__list-holder {
      &.catalog__list-holder-tablet-main {
        width: 175px;
        height: 145px;
        background-color: #FFFFFF;
        //border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 10px;
        border-radius: 10px;

        svg {
          display: none;
        }
      }
    }

    &__list-link-tablet-main {
      display: block;

      ul {
        display: flex;

        li {
          margin-right: 10px;

          a {
            display: block;
            padding: 0;

            img {
              width: 64px;
              height: 64px;
              margin-bottom: 0;
              margin-right: 0;
            }

            p {
              width: 100%;
              white-space: normal;
            }
          }
        }
      }
    }
  }
  .catalog__list {
    &.catalog__list-home-page {
      .catalog__list-link {
        width: 730px;
        border-radius: 0;
      }
    }
  }

  .popular-category {
    max-width: 100%;
    padding: 0 20px;
    margin-bottom: 91px;

    &__small-box {
      width: 315px;
      margin-right: 5px;
    }

    &__category-wrapper {
      display: flex;
      margin-bottom: 10px;
    }
  }
  .blog-wrapper {
    padding: 0 20px;
  }
  .blog-slider {
    padding-top: 20px;

    &__slide {
      margin: 0 10px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .home__slide {
    //height: 200px !important;
    p {
      font-size: 22px;
      margin-bottom: 5px;
      width: 100%;
    }

    span {
      font-size: 12px;
    }
  }
  .progressBarContainer div {
    width: 50px;
  }
  .home {
    &__first-banner {
      margin-bottom: 60px;
    }

    &__buttons {
      display: none;
    }
  }


  .catalog {
    //.catalog__list-link-tablet-main
    &__list.catalog__list-home-page {
      width: 100%;
    }

    &__list-link {
      //width: 300px;
      width: 100%;
      padding: 0 0 15px 0;
    }

    &__list-holder {
      &.catalog__list-holder-tablet-main {
        width: 115px;
        height: 105px;
      }
    }

    &__list-link-tablet-main ul li a {
      img {
        width: 40px;
        height: 40px;
        margin-right: 0;
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 110%;
        letter-spacing: -0.03em;
      }
    }
  }
  .catalog__list {
    &.catalog__list-home-page {
      .catalog__list-link {
        width: calc(100% + 20px);
      }
    }
  }

  .popular-category {
    padding: 0 0 0 20px;
    margin-bottom: 60px;

    &__title {
      font-size: 20px;
      margin-bottom: 15px;
    }

    &__categories {
      display: unset;
    }

    &__category {
      display: flex;
    }

    &__small-box {
      width: 138px;
      height: 160px;
      margin-right: 10px;
      padding: 20px 10px;
    }

    .small-box {
      &__image {
        align-items: center;
        flex-direction: column;
      }

      &__image-wrapper {
        width: 70px;
        height: 70px;
        margin-right: 0;
        margin-bottom: 5px;
      }

      &__title {
        font-size: 14px;
        line-height: 100%;
        text-align: center;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      }

      &__subtitle {
        font-size: 12px;
        line-height: 110%;
        letter-spacing: -0.03em;
      }

      &__title-wrapper {
        align-items: center;
        width: 100%;
      }
    }

    &__category-wrapper {
      margin-bottom: 15px;
    }

    &__category-wrapper:last-child {
      margin-bottom: 0;
    }
  }


  .about-us {
    .description__body {
      img {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;

        &:nth-child(2n+1) {
          margin-right: unset;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .product__image {
    .product__slider-nav {
      .slick-track {
        .slick-slide {
          height: 51px;

          img {
            min-width: unset;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .home__slide {
    height: 270px !important;

    a {
      height: 270px;
    }
  }
  .product-page {
    &.card-product-page {
      .product__image {
        .product__slider {
          height: 374px;

          .slick-list {
            .slick-track {
              .slick-slide {
                height: 374px;
              }
            }
          }
        }
      }

      .product__image {
        .product__slider-nav {
          .slick-track {
            .slick-slide {
              max-width: 40px;
              height: 40px;
            }
          }
        }
      }

      .product {
        &__image {
          &__slider-nav {
            max-width: unset;
            width: 100%;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 375px) {
  .home__slide {
    height: 240px !important;

    a {
      height: 240px;
    }
  }
  .product-page {
    &.card-product-page {
      .product__image {
        .product__slider {
          height: 335px;

          .slick-list {
            .slick-track {
              .slick-slide {
                height: 335px;
              }
            }
          }
        }
      }

      .product__image {
        .product__slider-nav {
          .slick-track {
            .slick-slide {
              max-width: 40px;
              height: 40px;
            }
          }
        }
      }

      .product {
        &__image {
          &__slider-nav {
            max-width: unset;
            width: 100%;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 320px) {
  .home__slide {
    height: 200px !important;

    a {
      height: 200px;
    }
  }
  .product-page {
    &.card-product-page {
      .product__image {
        .product__slider {
          height: 280px;

          .slick-list {
            .slick-track {
              .slick-slide {
                height: 280px;
              }
            }
          }
        }
      }

      .product__image {
        .product__slider-nav {
          .slick-track {
            .slick-slide {
              max-width: 40px;
              height: 40px;
            }
          }
        }
      }

      .product {
        &__image {
          &__slider-nav {
            max-width: unset;
            width: 100%;
          }
        }
      }
    }
  }


}

//POPULAR
.box-category-holder .box-category .item-category .image-holder img {

}

.product-page .popular__button-mobile {
  display: none;
}

.popular {
  position: relative;

  &__about-title {
    min-height: 66px;
  }

  &__button-desktop {
    a:focus {
      outline: -webkit-focus-ring-color auto 5px;
      outline-offset: -2px;
    }

    .in-cart__body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      background: #FFFFFF;
      border-radius: 10px;
      height: 100%;
      border: 2px solid #44BB6E;
      min-width: 135px;
      position: relative;

      button {
        background: none;
        border: none;
        width: 35px;
        height: 35px;
        border-radius: 10px;

        &:focus {
          background: rgba(68, 187, 110, 0.1);
        }

        svg {
          width: 12px;
          height: 12px;
        }
      }

      .popular__text {
        font-family: "Geometria", serif;
        font-weight: 700;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 90%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1F1F1F;
      }

      .checked-quantity {
        position: absolute;
        right: -10px;
        top: -10px;
      }
    }
  }

  .category-links {
    height: 768px;
    overflow: hidden;
    border-radius: 20px;

    &.show-more-popular {
      height: 100%;
      overflow: unset;
    }

    .holder {
      .box-category-holder {
        .box-category {
          &:hover {
            //box-shadow: 0px 25px 15px -10px rgba(190, 190, 190, 0.1), 0px 0px 40px rgba(0, 0, 0, 0.12) !important;
            //height: max-content;
          }

          .image-holder {
            img {
              border-radius: 10px;
            }
          }

          .item-category {
            //.action {
            //    top: 21px !important;
            //}
          }

          .block-addition {
            position: unset;
            border-top: none;
            padding: 0;
            //display: none;
            border-radius: 0;
            background: #fff;
            //box-shadow: 0px 25px 15px 0px rgba(190, 190, 190, 0.1), 0px 30px 40px rgba(0, 0, 0, 0.12);
            box-shadow: unset;
            //margin-bottom: 20px;
            margin-top: 20px;

            &__title {
              font-size: 14px;
              line-height: 120%;
              letter-spacing: -0.01em;
              color: #494949;
              margin-bottom: 0;
            }

            &__text {
              margin-bottom: 0;
              font-size: 14px;
              line-height: 120%;
              letter-spacing: -0.01em;
              color: #1F1F1F;
            }

            ul {
              text-align: left;

              li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid rgba(0, 0, 0, 0.14);
                padding-top: 7px;
                margin-bottom: 2px;

                select {
                  max-width: 100px !important;
                  display: flex;
                  align-items: center;
                  border: unset;
                  height: 16px;
                  padding: 0;
                  background: url(../images/select-arrow-down.png) 96% 1px no-repeat;

                  &:focus {
                    border: unset;
                  }
                }

                a {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;

                  span {
                    color: #44BB6E;
                  }
                }
              }
            }
          }

        }
      }

    }
  }

  .category-links {
    height: 766px;
    overflow: hidden;
    border-radius: 20px;

    &.show-more-popular {
      height: 100%;
      overflow: unset;
      //min-height: 768px;
    }

    .holder {
      .box-category-holder {
        .box-category {
          .image-holder {
            img {
              border-radius: 10px;
            }
          }

          .item-category {
            //.action {
            //    top: 26px !important;
            //}
            &.popular__item-category {
              align-items: center;
              position: relative;
            }

            &.popular__description-category {
              flex-grow: unset;
            }
          }
        }
      }
    }
  }

  &__background {
    width: 100%;
    height: 279px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 40.1%, #FFFFFF 58.33%);

    &.show-more-popular {
      background: #fff;
      position: unset;
      height: 139px;
      //  background: unset;
      //  transition: .25s;
      //z-index: -1;
    }
  }

  .old-price-holder .old-price .discount {
    width: max-content;
  }

  &__button {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;

    &-show-more {
      width: 313px;
      display: flex;
      align-items: center;
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #1F1F1F;
      padding: 24px 35px;
      background: #fff;
      border: 2px solid #1F1F1F;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 30px;
      outline: none;

      svg {
        margin-right: 10px;
        fill: #1F1F1F;
      }

      &:hover {
        background-color: #F8F8F8;
      }

      &:active {
        background-color: #EFEFEF;
      }

      &.show-more-popular {
        pointer-events: none;
        background-color: #E8E8E8;
        color: #ABABAB;
        border: 1px solid #E8E8E8;

        svg {
          fill: #ABABAB;
        }
      }
    }

    &-show-more-hide {
      display: none;
    }

    &-all-categories {
      width: 313px;
      align-items: center;
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      padding: 24px 35px;
      background: #44BB6E;
      border: 2px solid #44BB6E;
      box-sizing: border-box;
      border-radius: 10px;
      display: none;
      height: 76px;
      margin-left: 15px;

      &.filtered-active {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        text-align: center;

        &:hover {
          background: #73CC92;
          color: #fff;
          border: #73CC92;
        }

        &:active {
          background-color: #338C52;
        }

        &:focus {
          color: #fff;
        }

        span {
          text-transform: lowercase;
        }
      }
    }

    &.show-more-popular {
      //display: none;
    }

    //.popular__button-show-more.popular__button-show-more  {
    //    display: none;
    //}
    //category-links slider-tovar ng-scope show-more-popular
  }

  .category-links.show-more-popular .popular__button.show-more-popular .popular__button-show-more {
    //display: block;
  }

  .category-links.show-more-popular .popular__button.show-more-popular .popular__button-show-more.popular__button-show-more-hide {
    display: none;
  }

  &__title {
    flex-direction: column;
    text-align: left;

    p {
      margin-bottom: 20px;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    width: 100%;
    //height: 46px;
    &::-webkit-scrollbar-track {
      background-color: #FFFFFF;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      background-color: #FFFFFF;
    }

    &::-webkit-scrollbar-thumb {
      background: #D0D0D0;
      border-radius: 10px;
    }

    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 5px;
      margin-left: 3px;
      margin-top: 2px;

      li {
        margin-right: 10px;
        list-style: none;
        margin-bottom: 5px;

        &:last-child {
          margin-right: 0;
        }
      }

      button {
        width: max-content;
        background: #F8F8F8;
        border-radius: 6px;
        padding: 11px 15px;
        font-family: "Geometria", serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.01em;
        color: #1F1F1F;
        border: unset;
        outline: none;

        &:hover {
          background-color: #EFEFEF;
        }

        &.active {
          box-shadow: 0 0 0 2px rgba(68, 187, 110, 1);
          background-color: #fff;
          color: #44BB6E;

          span {
            color: #44BB6E;
          }
        }

        &:focus {
          opacity: 0.8;
          background-color: #fff;
          color: #44BB6E;
          box-shadow: 0 0 0 2px #44bb6e;
        }
      }
    }
  }

  &__product-block-mobile {
    display: none;
  }
}


@media (hover: hover) {
  .category-links {
    .holder {
      .box-category-holder {
        .box-category {
          &:hover {
            box-shadow: 0 25px 15px -10px rgba(190, 190, 190, 0.1), 0px 0px 40px rgba(0, 0, 0, 0.12) !important;
            height: max-content;
            background-color: #fff;
          }
        }
      }
    }
  }
  .sliderContainer:hover .background-gradient__progress-bar {
    //display: block;
    opacity: 1;
    transition: .25s;
  }
  .sliderContainer:hover .home__buttons {
    //display: flex;
    opacity: 1;
    transition: .25s;
  }
}

.wrap-holder.wrap-slider-rec-products.popular {
  max-width: unset;
  //margin: 100px auto 100px auto;
  h1 {
    text-align: left;
  }
}

.one-click-product {
  border-radius: 3px;
  height: 34px;
  border: 1px solid #17627e;
  position: relative;
  max-width: 210px;
  width: 100%;
  padding: 0 3px;
  display: inline-block;
  margin-top: 10px;

  &.error {
    border: 1px solid #DA1E28;
  }

  span {
    font: 12px/16px Geometria, sans-serif;
    font-weight: 400;
  }

  button {
    position: absolute;
    width: 30px;
    top: 0;
    right: 0;
    height: 32px;
    background: #44bb6e;
    color: #fff;
    border: none;
    line-height: 16px;
    font-size: 14px;
    font-family: "Geometria", serif;
    font-weight: 500;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: #E8E8E8;
      color: #ABABAB;
      border: 2px solid #E8E8E8;
    }
  }
}

.product__buy__top.catalog__buy-one-click {
  border: 0;
  height: 12px;
  padding: 0 2px;
  box-shadow: none;
  font: 12px/11px Geometria, sans-serif;
  font-weight: 400;
  width: 100%;
  //max-width: 175px;
  text-align: center;

  input {
    display: block;
    text-align: center;
    margin: 0 auto;
  }
}

//CATEGORY PAGE

.category-links {
  min-height: 500px;
}

//POPULAR TABLET

@media (min-width: 1000px) and (max-width: 1279px) {
  .wrap-slider-rec-products {
    .category-links.slider-tovar {
      .holder {
        height: 440px;

        .box-category-holder {
          height: 100%;

          .box-category {
            .item-category {
              width: 220px;

              .image-holder {
                &.image-size {
                  height: 220px;
                }
              }

              .description-category {
                .block-buy {
                  margin-bottom: 20px;

                  .btn-buy {
                    padding: 10px;
                    max-width: 122px;
                  }

                  .price-new {
                    font-size: 19px;

                    .old-price-holder {
                      height: unset;
                      margin-bottom: 5px;

                      .old-price {
                        font-size: 14px;

                        .discount {
                          font-size: 8px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .product-page {
    .popular {
      width: calc(100% + 40px);
      margin: 0 -20px;

      &__filter {
        ul {
          flex-wrap: unset;
        }

        &-background {
          background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
          width: 37px;
          height: 38px;
          position: absolute;
          right: 22px;
        }
      }

      .slider-tovar {
        .holder {
          width: 33.3%;
          min-width: 256px;
          height: 400px;
        }
      }

      .wrap {
        border-radius: 0;
      }

      &__button-desktop {
        display: none;
      }


      &__button-mobile {
        display: block;
      }

      .new-items__buy-button {
        right: 25px;
        top: 16px;
      }

      .wrap-slider-rec-products__header {
        padding: 30px 23px 19px 23px;
      }

      &__product-discount {
        width: max-content;
        font-size: 9px;
        line-height: 120%;
        letter-spacing: -0.03em;
        color: #FFFFFF;
        background: #E3565E;
        border-radius: 8px;
        padding: 2px 4px;
      }
    }

    &.card-product-page {
      .wrap-holder.wrap-slider-rec-products {
        .category-links.slider-tovar {
          .holder {
            height: 400px;

            .box-category-holder {
              .box-category {
                .item-category {
                  width: 100%;
                  padding: 10px 20px;

                  .image-holder {
                    width: 216px;
                    height: 216px;
                  }

                  &.popular__item-category {
                    .action {
                      left: 26px;
                      top: 16px;
                    }
                  }

                  &.latest-product__item-category {
                    .action {
                      left: 18px;
                      top: 16px;
                    }

                    .new-items__buy-button {
                      right: 18px;
                      top: 16px;
                    }
                  }

                  &.similar-products__item-category {
                    padding: 10px 15px;

                    .action {
                      top: 16px;
                      left: 19px;
                    }

                    .new-items__buy-button {
                      right: 20px;
                      top: 17px;
                    }
                  }

                  &.viewed-products__item-category {
                    padding: 10px 15px;

                    .action {
                      top: 16px;
                      left: 19px;
                    }

                    .new-items__buy-button {
                      right: 20px;
                      top: 17px;
                    }
                  }

                }
              }
            }
          }
        }
      }

    }
  }

  .popular .category-links .holder .box-category-holder .box-category .block-addition {
    display: none;
  }
  .wrap-slider-rec-products {
    .category-links.slider-tovar {
      .holder {
        .box-category-holder {
          .box-category {
            &:hover {
              height: 100%;
            }
          }
        }
      }
    }
  }
  .wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .description-category .block-buy {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .product-page {
    .popular {
      .slider-tovar .holder {
        width: 50%;
        min-width: unset;
        height: 310px;

        .category-links .box-category-holder .box-category .item-category .image-holder {
          height: 140px;
        }
      }

      &__product-button-mobile {
        position: unset;
        display: flex;
        flex-direction: unset;
      }

      &__product-button-desktop {
        display: none;
      }

      &__filter-background {
        right: 19px;
      }

      &__button button {
        padding: 12px;
        width: max-content;
        font-size: 14px;
      }

      .category-links {
        //height: 589px;
      }

      &__product-block-mobile {
        display: block;
      }

      .wrap-slider-rec-products__header {
        padding: 20px;

        p {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }

      &__filter {
        height: 48px;

        ul {
          li {
            margin-bottom: 0;
          }
        }
      }

      &__button {
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
      }

      .popular__button-all-categories {
        width: calc(100% - 20px);
        height: max-content;
        font-size: 14px;
        padding: 12px;
        margin-left: 0;
        margin-bottom: 10px;
      }

      &__button {
        button {
          width: calc(100% - 40px);
          justify-content: center;
          margin-bottom: 20px;
        }
      }
    }

    &.card-product-page {
      .wrap-holder.wrap-slider-rec-products {
        .category-links.slider-tovar {
          .holder {
            height: 310px;
          }
        }
      }
    }
  }
  .wrap-slider-rec-products {
    .category-links {
      min-height: unset;
      //min-height: 558px;
      //height: max-content;
      //max-height: 558px;

      &.slider-tovar {
        .holder {
          .box-category-holder {
            .box-category {
              .item-category {
                .description-category {
                  justify-content: space-between;
                }
              }
            }
          }
        }
      }
    }
  }

  .wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .description-category .title .about-title {
    margin-bottom: 4px;

    a {
      font-size: 11px;
      line-height: 130%;
    }
  }
  .wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .substrate {
    height: 45px !important;
  }
  .product-page.card-product-page .wrap-holder.wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .image-holder.image-size,
  .basket-page .viewed_product__wrapper .wrap-holder.wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .image-holder.image-size {
    width: 120px;
    height: 120px;
  }
  .product-page.card-product-page .wrap-holder.wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .image-holder.image-size.popular__image-holder {
    width: 140px;
    height: 140px;
  }
  .product-page.card-product-page {
    .wrap-holder.wrap-slider-rec-products {
      .category-links.slider-tovar {
        .holder {
          .box-category-holder {
            .box-category {
              .item-category {
                &.popular__item-category {
                  padding: 10px;

                  .action {
                    left: 15px;
                    top: 15px;

                    p {
                      font-size: 9px;
                      line-height: 120%;
                    }

                    span {
                      width: 5px;
                      height: 5px;
                    }
                  }
                }

                &.latest-product__item-category {
                  padding: 10px;

                  .action {
                    left: 16px;
                    top: 16px;

                    p {
                      font-size: 9px;
                      line-height: 120%;
                    }

                    span {
                      width: 5px;
                      height: 5px;
                    }
                  }
                }

                &.similar-products__item-category {
                  padding: 10px;

                  .action {
                    left: 16px;
                    top: 16px;

                    p {
                      font-size: 9px;
                      line-height: 120%;
                    }

                    span {
                      width: 5px;
                      height: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .basket-page .viewed_product__wrapper .wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category {
    width: 100%;
    padding: 10px;
  }

  .wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .wrap-star {
    margin-bottom: 10px;
  }
  .wrap-holder .box-category-holder .box-category .item-category .description-category .btn-holder, .wrap-holder .box-category-holder .box-category .item-category .description-category .price-new {
    font-size: 18px;
    text-align: left;
    margin-bottom: 0;
  }
  .wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .description-category .block-buy {
    margin-bottom: 0;
    align-items: center;

    .price-new {
      display: none;
    }
  }
  .old-price-holder {
    .discount {
      display: inline-block;
    }
  }
  .popular .category-links .holder .box-category-holder .box-category .block-addition {
    display: none;
  }
  .wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category:hover {
    height: 100%;
  }
  .one-click-product {
    text-align: left;

    span {
      font: 11px/16px 'Geometria', sans-serif;
      font-weight: 400;
    }
  }
  .product__buy__top.catalog__buy-one-click {
    font: 9px/11px 'Geometria', sans-serif;
    font-weight: 400;
    text-align: left;

    input {
      text-align: left;
    }
  }
}

//CATEGORY PAGE

.category-links {
  min-height: 500px;
}

.zakaz_page {
  padding: 30px 0 20px 0;

  h3 {
    text-transform: uppercase;
  }

  &-addit {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .product {

  }
}

// CART POPUP OPTION

.cart-popup_option {
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #1F1F1F;
  font-weight: bold;
  height: 33px;
  justify-content: center;
  margin: 0 4px 0 0;
  position: relative;

  :hover {
    cursor: pointer;
  }

  &__list {
    position: absolute;
    top: 25px;
    left: -1px;
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    div {
      padding: 5%;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.14);

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.__jivoMobileButton {
  margin-bottom: 40px;
}

.basket .present-product .basket-holder .basket-item .basket-item__quantity {
  padding-right: 0;
}

.basket-item__delimiter {
  margin: 0 12px;
  font-size: 24px;
}

@media screen and (max-width: 568px) {
  .basket-item__delimiter {
    font-size: 14px;
  }
}

.download {
  background: #44BB6E;
  border-radius: 10px;
  border: 0;
  color: #FFFFFF;

  i {
    background-position: -66px -575px;
  }

  &:hover {
    background: #73CC92;
  }

  &:active {
    background-color: #338C52;
  }
}
