header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}

.header__basket-mobile {
  display: none;
}

.header__mobile-icon-close {
  display: none;
}

.header__mobile-burger {
  display: none;
}

.header-middle {
  padding: 0;
  border-top: none;
  border-bottom: none;
}

.callback-mobile-icon {
  display: none;
}

.callback-desktop-icon {
  display: block;
}

.mobile-cart {
  display: none;
}

.comparison-page .box-category-holder .box-category .item-category .image-holder img {
  height: 175px;
}

.carousel-indicators {
  z-index: 4;
}

.header__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  //justify-content: space-between;
}

.header-bottom {
  margin-bottom: 0;

  .catalog {
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 25;
  }
}

.header-middle .header-middle-child .logo .logo__link {
  width: 71px;
  height: 50px;
  padding: 0;
  margin-left: 0;
  margin-right: 15px;
}

.header-middle .header-middle-child .logo {
  //width: 19%;
  width: unset;
  min-width: 278px;
  display: flex;
  align-items: center;
  padding: 0;
  border-right: unset;
  min-height: 87px;
  //margin-right: 42px;
}

.header-middle .header-middle-child .logo .text-item {
  display: block;
  font: 12px/110% 'Geometria', sans-serif;
  font-weight: 500;
  width: 170px;
  min-width: 170px;
  max-width: 170px;
  padding: 0;
  letter-spacing: -0.03em;
  color: #494949;
}

.header-bottom .menu {
  float: left;
  width: max-content;
  padding: 0 0 0 0;
  min-height: 87px;
  display: flex;
  align-items: center;
}

.header-bottom .menu ul {
  text-align: left;
  height: 37px;
}

.header-bottom .menu ul li {
  //padding: 5px 10px 5px;
  padding: 0;
  margin: 0;

  &:first-child a {
    padding: 5px 10px 5px;
    margin-bottom: 0;
    border: unset;
    border-radius: 50px;
    background: #da1e28;
    color: white;
  }

  &:first-child a {
    &:hover {
      background: #da1e28 !important;
      color: white !important;
    }
  }
}

.header-bottom .menu ul li a {
  font: 14px 'Geometria', sans-serif;
  font-weight: 500;
  letter-spacing: -0.01em;
  padding: 5px 10px 5px;
  margin: 0;
  //transition: 0.25s;
}

.header-bottom .menu ul li .active {
  padding: 5px 10px 5px;
  margin-bottom: 0;
  border: unset;
  border-radius: 50px;
  background: linear-gradient(0deg, #E3F5E9, #E3F5E9), linear-gradient(0deg, #E5E8FC, #E5E8FC), #E6DFFB;
  color: #1F1F1F;
  //transition: 0.25s;
}

.header-bottom .menu ul li a:hover {
  padding: 5px 10px 5px;
  margin-bottom: 0;
  border: unset;
  border-radius: 50px;
  background: linear-gradient(0deg, #E3F5E9, #E3F5E9), linear-gradient(0deg, #E5E8FC, #E5E8FC), #E6DFFB;
  color: #1F1F1F;
  transition: 0.25s;
}

.header-middle .header-middle-child .callback {
  border: none;
  min-height: 82px;
  padding: 0;
  width: 100%;
  min-width: 265px;
  max-width: 265px;
}

.header__right-wrapper {
  margin-left: auto;
  position: relative;
}

.header-middle .header-middle-child .callback a span:before {
  background: unset;
}

.callback-drop-down {
  //display: flex;
  //align-content: center;
}

.callback {
  &__drop-down-phone {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 82px;
    margin-right: 30px;
    min-width: 265px;
  }

  &__phone-wrapper {
    display: flex;
    align-items: center;
  }

  &__phone-wrapper .btn-callback {
    background: unset;
    font-family: 'Geometria', serif;
    font-weight: 500;
    color: #1F1F1F !important;
    letter-spacing: -0.01em;
    //margin-bottom: 3px;
    margin-bottom: 0;
    padding: 0;
    font-size: 20px;
    height: 100%;

  }

  &__phone {
    text-align: left;

    a {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      cursor: pointer;
    }
  }

  &__more-phone {
    width: 265px;
    height: auto;
    background: #fff;
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.1), 0 20px 19px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 8px 8px;
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 96%;
    z-index: 26;
    border-top: 1px solid rgba(0, 0, 0, 0.14);
  }

  &__more-phone.active {
    display: block;
    z-index: 101;
  }

  &__phone-link {
    padding: 21px 20px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);

    a {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-family: "Geometria", serif;
      font-weight: 500;
      color: #000000;
      font-size: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        margin-right: 5px;
      }
    }
  }

  &__enter-phone {
    padding: 20px 20px 30px 20px;

    input {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.14);
      border-radius: 10px;
      color: #727272;
      padding: 15px;
      letter-spacing: -0.01em;
      font-size: 14px;
      font-family: 'Geometria', serif;
      font-weight: 300;
      margin-bottom: 15px;

      &.error {
        border-color: red;
      }
    }

    button {
      border: 2px solid #44bb6e;
      border-radius: 8px;
      font-family: "Geometria", serif;
      font-weight: 400;
      font-size: 14px;
      padding: 15px 20px 15px 20px;
      text-align: center;
      width: 100%;
      background: #44bb6e;
      transition: 0.25s;
      color: white;

      &:hover {
        background: #338C52;
        transition: 0.25s;
        border: 2px solid #338C52;
      }

      &:disabled {
        opacity: .65;
        color: black;
        border: 2px solid black;
        background-color: white;
        transition: 0.25s;
      }
    }
  }

  &__modal {
    width: 310px;
    height: 140px;
    background: #fff;
    border-radius: 8px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 105;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &-backdrop {
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 102;
    }
  }
}


.header-middle .header-middle-child .callback span {
  margin: 0;
  height: max-content;
  font-weight: 500;
  font-size: 12px;
  font-family: "Geometria", serif !important;
  color: #494949;
}

.header-middle .header-middle-child .callback a span {
  margin: 0;
  display: flex;
  align-items: center;
  font-family: "Geometria", serif !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.header__language {
  width: 6%;
  display: flex;
  align-items: center;

  span {
    margin: 0 12px 0 12px;
    //color: #9c9c9c;
  }
}


.header-middle .header-middle-child .callback {

}

//.callback:hover {
//  box-shadow: 0px -9px 20px rgba(0, 0, 0, 0.1), 0px 10px 35px rgba(0, 0, 0, 0.15);
//  border-radius: 8px 8px 0 0;
//  //transition: 0.25s;
//}

.callback__drop-down-phone .callback__phone svg {
  transition: 0.25s;
}

//.callback__drop-down-phone:hover .callback__phone svg {
//  transform: rotate(180deg);
//  transition: 0.25s;
//}


//.callback:hover .callback__more-phone {
//  display: block;
//  transition: 0.25s;
//}


.callback.active {
  box-shadow: 0px -9px 20px rgba(0, 0, 0, 0.1), 0px 10px 35px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 0 0;
  //transition: 0.25s;
}

.callback__drop-down-phone .callback__phone svg.active {
  transform: rotate(180deg);
  transition: 0.25s;
}

.callback__mobile {
  display: none;
}

.header-middle .header-middle-child .callback a {
  display: flex;
  align-items: center;
}

.header__language-button {
  font-size: 12px;
  font-family: "Geometria", serif !important;
  font-weight: 500;
  border: unset;
  background: unset;
  padding: 0;
  color: #9C9C9C;
  text-transform: uppercase;

}

.header__language-button.active {
  color: #1F1F1F;
}

.header__language-mobile {
  display: none;
}

.modal-u.header__cart-modal {
  background: none;
  visibility: unset;
  opacity: unset;
  top: 1px;
}

.header__modal-u.modal-u {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%);
}

.header__basket-wrapper {
  position: relative;
}

.added-to-cart__body.header-cart__body {
  //margin: 20px 0 0 0;
  //min-height: unset;
  //max-height: unset;
}

//.added-to-cart__body.header-cart__body .added-to-cart__product {
//  min-height: 304px;
//  max-height: 304px;
//}
//.added-to-cart__body.header-cart__body .header__basket-empty {
//  min-height: unset;
//  max-height: unset;
//}
.header__basket-empty {
  //width: 350px;
  //position: absolute;
  //top: 42px;
  //right: 0;
  //z-index: 2;
  //border-radius: 0 0 8px 8px;

  h4 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #1F1F1F;
    font-family: "Geometria", serif;
    font-weight: 500;
    margin-right: 4px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #44BB6E;
    border-radius: 8px;
    height: 16px;
    flex-grow: 1;
    border: 0;
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    outline: none;
    width: 100%;
  }
}

.header__basket-full.modal-u__body.active-full-basket {
  display: block;
  z-index: 103;
  transition: 0.25s;
}

.header__basket-full .added-to-cart__total {
  p {
    span {
      position: unset;
      background-color: unset;
      border-radius: unset;
      width: unset;
      height: unset;
      display: inline;
      cursor: unset;
    }
  }
}

.header__basket-full.header__basket-mobile {
  display: none;
  transition: 0.25s;
}

.header__basket-full.modal-u__body {
  display: none;
  position: absolute;
  min-width: 350px;
  top: 100%;
  transform: translateY(40px);
  right: 0;
  max-width: 450px;
  max-height: 521px;
  border-radius: 0 0 8px 8px;
  padding: 20px 30px 30px 30px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1), 0px 10px 35px rgba(0, 0, 0, 0.15);
  @media screen and (max-width: 767px) {
    width: 100% !important;
    min-width: auto;
    height: auto;
    max-height: none;
  }
}

.fast-buy__popup {
  position: absolute;
  min-width: 350px;
  top: 25vh !important;
  right: 25vw !important;
  max-width: 450px;
  max-height: 521px;
  border-radius: 8px 8px 8px 8px !important;
}

.fast-buy__text {
  display: none;
  text-align: center !important;
}

.header {
  &__basket-full {
    .added-to-cart__header {
      p {
        max-width: 100%;
        text-align: left;
        margin: 0;
        font-size: 20px;
        line-height: 28px;
      }
    }

    .quick-order__cart {
      transition: 0.25s;

      &:hover {
        transition: 0.25s;
        background: #EFEFEF !important;
      }
    }

    .checkout-order {
      transition: 0.25s;

      &:hover {
        background: #73CC92;
        transition: 0.25s;
      }
    }
  }

}


.quick-order {
  max-width: 143px;
  background: #F8F8F8 !important;
  color: #1F1F1F !important;
  margin-right: 15px !important;
}

.checkout-order {
  flex-grow: 1;
  padding: 0 10px;
  height: 44px;
  border: 0;
  background: #F8F8F8;
}


.catalog__wrapper {
  background: #1F1F1F;

}

.catalog {
  display: flex;
  align-items: center;
  min-height: 60px;
  position: relative;
}

.search-holder {
  font: 14px/16px 'Geometria', sans-serif;
  font-weight: 300;
  border: none;
  height: 40px;
  //width: 58%;
  width: 100%;
  position: relative;
  border-radius: 8px;
  //z-index: 101;
  z-index: 22;
  float: unset;
  top: 0;
  margin-right: 10px;
  overflow-x: unset;
  overflow-y: visible;

  &.search-holder__search-page {
    z-index: 1;
  }
}


.search-holder .search-by:after {
  display: none;
}

.cart_phone_popup:focus + .label {
  display: none;
}

.search-holder .form-control {
  background: #494949;
  height: 40px;
  border-radius: 8px;
  color: #D0D0D0;
  padding: 1px 175px 1px 62px;

  //&:focus + a {
  //  display: none;
  //}

  &::-webkit-input-placeholder {
    color: #D0D0D0;
  }

  &::-moz-placeholder {
    color: #D0D0D0;
  }

  &:-ms-input-placeholder {
    color: #D0D0D0;
  }

  &:-moz-placeholder {
    color: #D0D0D0;
  }
}

.search-result {
  border: 1px solid #1F1F1F;
  border-radius: 8px;
  left: 0;
  width: 100%;
  background: #fff;
  top: 40px !important;

  .inner-search {
    border-bottom: 1px solid #727272;
    padding: 8px 10px;
    cursor: pointer;

    a {
      color: #494949;
    }
  }
}

.search-result .inner-search .text-block {
  width: 90%;
}

.back-drop {
  width: 100%;
  height: 100vh;
  background-color: #000000;
  opacity: .15;
  position: absolute;
  z-index: 25;
  left: 0;
  top: 80px;
  transition: .25s;
}

.catalog__backdrop {
  transition: .25s;
}


.catalog__button {
  cursor: pointer;
  margin-right: 10px;
  //position: relative;

  &.hover-main {
    transition-delay: 1s;

    .catalog__list {
      transition-delay: .1s;
      display: block;
      z-index: 101;
    }

    button {
      background: #338C52;
      transition: 0.25s;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: none;
    background: #44BB6E;
    border-radius: 8px;
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
    padding: 10px 30px;
    min-width: 213px;
    transition: 0.25s;

    &:hover {
      //background: #338C52;
      //transition: 0.25s;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    svg {
      margin-right: 15px;
    }
  }
}


.search-icon {
  position: absolute;
  z-index: 1;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
}

.search-relinking {
  position: absolute;
  bottom: 2px;
  left: 280px;
  font-family: 'Geometria', serif;
  font-weight: 400;

  span {
    color: #D0D0D0;
    font-size: 12px;
    transition: .25s;
  }

  a {
    color: #D0D0D0;
    font-size: 12px;
    transition: .25s;
    text-decoration: underline;

    &:hover {
      color: #fff;
      transition: .25s;
    }

    &:focus {
      color: #fff;
    }
  }


}

.search-relinking-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search-holder .search-by {
  display: flex;
  align-items: center;
  color: #fff;
  background: #727272;
  border-radius: 8px;
  top: 0;
  height: 100%;
  padding: 0 29.5px;
  font-family: "Geometria", serif;
  font-weight: 400;

  svg {
    display: none;
  }
}

.cart-text {
  margin-right: 5px;
}

.added-to-cart__controls {
  .quick-label {
    background: #fff;
    top: 53%;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #727272;
    font-family: "Geometria", serif;
    font-weight: 400;
    transform: translateY(-50%);
    width: calc(100% - 2px);
    left: 1px;
    text-align: left;
    padding-left: 14px;
    pointer-events: none;
    @media screen and (max-width: 568px) {
      top: 42%
    }

    i {
      color: red;
      position: unset;
    }
  }

  .checkout-wrap {
    @media screen and(min-width: 768px) {
      flex-direction: row !important;
      .tel-wrapper {
        width: 100% !important;
      }
    }
    @media screen and(max-width: 768px) {
      .btn-buy {
        max-width: 100%;
      }
    }
  }

  .quick-input {
    border: 1px solid rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.01em;
    font-family: "Geometria", serif;
    font-weight: 400;
    @media screen and (max-width: 568px) {
      width: 100% !important;
    }
  }

  .quick-button {
    border-radius: 8px;
    font-size: 14px;
    line-height: 100%;
    font-family: "Geometria", serif;
    font-weight: 400;
    letter-spacing: 0.01em;
    height: 44px;


    min-width: 124px;
    width: 124px;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-buy {
      min-width: 124px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
}

.cart-wrap {
  display: flex;
  align-items: center;
  min-width: 341px;
}

.cart-wrap .bucket {
  margin-left: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  height: 80px;
  padding: 0 20px;
  margin: -40px 0;

  svg {
    height: 24px;
    margin-right: 8px;
  }
}

.bucket.busket-hover {
  background-color: #494949;
}

.cart-count-text {
  display: flex;
  align-items: center;
  font-family: "Geometria", serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #fff;
}

.cart-count-dot {
  position: absolute;
  top: -24px;
  left: 42px;
}

.cart-count-dot.comparison-dot {
  top: 8px;
  left: 42px;
}

.cart-count-dot.wish-list-dot {
  top: 8px;
  left: 42px;
}

.cart-wrap .bucket span {
  background: none;
  //margin-left: 5px;
  display: block;
  margin: 0 2.5px;
}

.cart-wrap a span {
  background: none;
  width: unset;
  height: unset;
  padding: 0;
  display: none;
}

//.header__basket-full .added-to-cart__body-wrapper .background

.checkout-wrap .tel-wrapper label {
  font-family: "Geometria", serif;
  font-weight: 400;
  transform: translateY(-100%);
}

.added-to-cart__body-wrapper {
  position: relative;
  max-height: 340px;
  max-width: 390px;

}

.header__basket-full .added-to-cart__body-wrapper .background {
  position: absolute;
  height: 52px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 63.02%);
  width: calc(100% - 20px);
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.28s opacity, 0.28s visibility;
}

.header__basket-full .added-to-cart__body-wrapper .background.active {
  opacity: 1;
  visibility: visible;
}

.header__basket-full .added-to-cart__body-wrapper .background .background {
  position: absolute;
  height: 52px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 63.02%);
  width: calc(100% - 20px);
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.28s opacity, 0.28s visibility;
}

.header__basket-full.modal-u__body .added-to-cart__header:after {
  width: calc(100% + 60px);
  left: -30px;
}

.header-delivery {
  background: #f0f5ef;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  //padding-right: 15%;
  //min-width: 100%;
  //padding-left: 13.5%;
}

.header-delivery__text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  font-size: 12px;
  color: #494949;
  letter-spacing: -0.03em;
  font-family: "Geometria", serif;
  font-weight: 500;

  img {
    margin-right: 6px;
  }
}

.tooltip-account {
  position: relative;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 0 20px;
}

.tooltip-account span {
  display: none;
  padding: 2px 3px;
  margin-left: 8px;
  width: 130px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -12px;
    left: 50%;
    border: 8px solid transparent;
    border-bottom: 4px solid #1F1F1F;
    transform: translateX(-50%);
  }
}


.tooltip-account:hover span {
  display: inline;
  position: absolute;
  background: #1F1F1F;
  color: #fff;
  border-radius: 6px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 13px);
  width: max-content;
  margin: 0;
  padding: 10px 15px;
}

.catalog__list.b-show {
  display: block;
  -webkit-animation: catalogBlockHidden 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: catalogBlockHidden 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes catalogBlockHidden {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes catalogBlockHidden {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes addDisplayblock {
  100% {
    display: block;
  }
}

.menu-block {
  display: block !important;
}

.menu-block-right {
  //display: block!important;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 300px;
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  border-radius: 10px;
  //height: 493px!important;
  overflow-y: hidden;
}

.catalog__hidden {
  display: none;
}

.catalog__subcategory {
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #1F1F1F;
    letter-spacing: -0.01em;
    font: 16px/20px 'Geometria', sans-serif;
    font-weight: 400;
    padding: 11px 0 11px 0;
    position: relative;
    margin: 0;
    overflow: hidden;

    &:after:hover {
      content: '';
      position: absolute;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 32%, rgb(250, 0, 0) 60%);
    }
  }
}

.catalog__list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  display: none;
  transition-delay: 2s;
  //margin-right: 15px;
  animation: catalogBlockHidden .5s linear forwards;
  padding-top: 60px;

  &.catalog__list-home-page {
    position: unset;
    padding-top: 0;
    animation: unset;
    //z-index: 100;
    z-index: 9;
    width: 24.2%;

    .catalog__list-link {
      border-radius: 20px;
      box-shadow: unset;
      //width: 330px;
      //width: 25.9%;
      width: 100%;
      margin-right: 15px;
    }

    .catalog__list-description {
      overflow: unset;
      margin-left: 179px;
      margin-top: 20px;

      &::-webkit-scrollbar {
        width: 0;
        background-color: unset;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        margin-bottom: 0;

      }

      &::-webkit-scrollbar-track {
        margin-bottom: 0;
      }
    }

    .catalog__list-aditional-holder {
      &:after {
        content: unset;
        position: unset;
      }
    }

  }

  &-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 22px;
  }

  &-link {
    padding: 18px 0 15px 0;
    background: #FFFFFF;
    width: 300px;
    height: 100%;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 10px 35px rgba(0, 0, 0, 0.15);


    &-wrapper {
      ul {
        li {
          list-style: none;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #1F1F1F;
            letter-spacing: -0.01em;
            font: 16px/20px 'Geometria', sans-serif;
            font-weight: 400;
            padding: 11px 0 11px 0;
            position: relative;
            margin: 0;
            overflow: hidden;
            //white-space: normal;
            @media screen and(max-width: 768px) {
              display: flex;
              align-items: center;
              width: 30%;
              height: auto;
              color: #1F1F1F;
              letter-spacing: -0.01em;
              font: 16px/20px 'Geometria', sans-serif;
              font-weight: 400;
              padding: 11px 0 11px 0;
              position: relative;
              margin: 0;
              overflow: hidden;
              white-space: nowrap;
              font-size: 14px;
              &:first-child {
                width: 100%;
              }
            }

            &:after:hover {
              content: '';
              position: absolute;
              //background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 32%, rgb(250, 0, 0) 60%);
            }
            //&:after {
            //  content: '';
            //  position: absolute;
            //  right: 0;
            //  top: 0;
            //  width: 40px;
            //  height: 100%;
            //  background: linear-gradient(
            //                  90deg
            //          , rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.48223) 50%, white 100%);
            //  transition: .5s ease;
            //}

            img {
              margin-right: 8px;
            }

            svg {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              margin-right: 22px;
            }
          }
        }
      }
    }
  }

  &-holder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 41px;
    padding: 0 0 0 27px;
    //position: relative;
    transition: .5s ease;
    cursor: pointer;
    @media screen and(max-width: 768px) {
      justify-content: space-between;
    }
    &.hover-active {
      background: #EFEFEF;
      transition: .5s ease;

      svg {
        position: absolute;
        //right: 26px;
        box-shadow: -10px 0px 10px 1px #efefef;
        background: #EFEFEF;
        transition: .5s ease;
      }
    }

    //&:hover {
    //  background: #EFEFEF;
    //  transition: .5s ease;
    //
    //  svg {
    //    position: absolute;
    //    //right: 26px;
    //    box-shadow: -10px 0px 10px 1px #efefef;
    //    background: #EFEFEF;
    //    transition: .5s ease;
    //  }
    //}

    p {
      margin: 0;
      white-space: nowrap;
      width: 71.5%;
      overflow: hidden;
    }

    a {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 140%;
      font-family: "Geometria", serif;
      font-weight: 400;
      letter-spacing: -0.01em;
      color: #1F1F1F;

      img {
        margin-right: 8px;
      }

      svg {
        position: absolute;
        right: 26px;
        box-shadow: -10px 0px 10px 1px white;
        transition: .5s;
      }
    }
  }

  &-description {
    display: none;
    margin-left: 170px;
    padding: 18px 0 31px 0;
    background: #FFFFFF;
    border-radius: 0 8px 8px 0;
    border-left: 1px solid rgba(0, 0, 0, 0.14);
    height: auto;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 60px;
  }

  &-holder.hover-active .catalog__list-description {
    display: block;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 330px;

    -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    border-radius: 0 20px 20px 0;
    height: 484px;
  }

  .catalog__list.catalog__list-home-page {
    position: unset;
    padding: 0;
  }

  .catalog__list-description-scroll {
    overflow-y: auto;
    height: 445px;

    &::-webkit-scrollbar {
      width: 3px;
      background-color: rgba(255, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b7b7b7;
      margin-bottom: 15px;

    }

    &::-webkit-scrollbar-track {
      margin-bottom: 15px;
    }
  }

  //
  //.catalog{
  //  &__list-home-page {
  //
  //  }
  //  &__list-link-home-page {
  //    background-color: red;
  //    border-radius: 20px;
  //    width: 330px;
  //    box-shadow: none;
  //  }
  //}


  //.catalog{
  //  &__list{
  //    &.catalog__list-home-page {
  //
  //    }
  //    &-link {
  //      &.catalog__list-link-home-page {
  //        width: 330px;
  //      }
  //    }
  //  }
  //
  //}

  .catalog__list-description::-webkit-scrollbar {
    width: 3px;
    background-color: rgba(255, 0, 0, 0);
    margin-bottom: 25px;
    padding-bottom: 25px;
    margin-top: 25px;
    padding-top: 25px;
    height: 400px;
  }

  .catalog__list-description::-webkit-scrollbar-thumb {
    background-color: #b7b7b7;
    margin-bottom: 15px;

  }

  .catalog__list-description::-webkit-scrollbar-track {
    margin-bottom: 15px;
  }

  &-aditional-holder {
    width: 100%;
    height: 41px;
    padding: 0 0 0 30px;
    white-space: nowrap;
    overflow: hidden;

    position: relative;

    //&:after {
    //  content: '';
    //  position: absolute;
    //  right: 0;
    //  top: 0;
    //  width: 40px;
    //  height: 100%;
    //  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4822303921568627) 50%, rgba(255, 255, 255, 1) 100%);
    //  transition: .5s ease;
    //}
a {
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(
                    90deg
            , rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.48223) 50%, white 100%);
    transition: .5s ease;
  }
}


    &:hover {
      background: #EFEFEF;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(239, 239, 239, 0.9136029411764706) 0%, rgba(239, 239, 239, 1) 50%, rgba(239, 239, 239, 1) 100%);
        width: 40px;
        height: 100%;
        z-index: 5;
      }

    }

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #1F1F1F;
      letter-spacing: -0.01em;
      font: 16px/20px 'Geometria', sans-serif;
      font-weight: 400;
      padding: 11px 0 11px 0;
      position: relative;
      margin: 0;
      overflow: hidden;
    }
  }

  &-aditional-description {
    display: none;
    margin-left: 269px;
    padding: 30px 0 31px 30px;
    background: #FFFFFF;
    border-radius: 0 8px 8px 0;
    border-left: 1px solid rgba(0, 0, 0, 0.14);
    height: auto;

    ul {
      li {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          font-family: "Geometria", serif;
          font-weight: 300;
          font-size: 14px;
          letter-spacing: -0.01em;
          color: #494949;
          padding: 8px 0 8px 0;

          &:first-child {
            padding-top: 10px;
          }
        }
      }
    }
  }

  &-aditional-links {
    display: flex;
    flex-direction: column;

    a {
      font-family: "Geometria", serif;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: -0.01em;
      color: #494949;
      padding: 8px 0 8px 0;

      &:first-child {
        padding-top: 10px;
      }
    }

    span {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: -0.01em;
      color: #1F1F1F;
      margin-bottom: 0;
    }

  }


  &-aditional-holder:hover .catalog__list-aditional-description {
    display: none;
    position: absolute;
    top: 0;
    z-index: -2;
    width: 300px;
    height: auto;
  }


}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

//FOOTER

footer {
  background: #000;
  overflow: hidden;

  .footer-holder__wrapper {
    //max-width: 1500px;
    //padding: 60px 45px 0;
    //margin: 0 auto;
    position: relative;
  }

  .footer-holder__wrapper .menu-footer {
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
  }

  .footer-holder__wrapper .menu-footer li a {
    color: #fff;
    font: 16px/20px 'Geometria', sans-serif;
    font-weight: 400;
    letter-spacing: 0;
  }

  .footer-holder__wrapper .menu-footer li {
    margin-bottom: 15px;
    display: flex;
    padding: 0;
    text-align: left;
    max-width: 100%;

    &:nth-last-child(2) {
      padding-top: 20px;
    }
  }

  .footer {
    &-holder__wrapper {
      //max-width: 1500px;
      //padding: 60px 0 0;
      padding-top: 60px;
      //margin: 0 auto;
      position: relative;
      line-height: normal;
    }

    &__wrapper {
      display: flex;
      //padding-left: 45px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background: #1F1F1F;
        left: -50%;
        bottom: 0;
      }
    }

    &__left {
      width: 24.5%;
      position: relative;
    }

    &__right {
      width: 75%;
      height: auto;
      background: #1F1F1F;
      margin-top: -60px;
      padding: 60px 45px 60px 60px;
      position: relative;
      z-index: 1;
      line-height: normal;

      &:after {
        position: absolute;
        content: '';
        right: -50%;
        top: 0;
        background: #1F1F1F;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 auto 30px 0;
    }

    &__general-phone {
      display: flex;
      align-items: center;
      width: 320px;

      svg {
        margin-right: 15px;

      }

      a {
        font-size: 32px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        font-family: "Geometria", serif;
        font-weight: 500;
      }
    }

    &__work-time {
      p {
        font-size: 12px;
        font-family: "Geometria", serif;
        font-weight: 400;
        letter-spacing: -0.03em;
        color: #727272;
        margin-bottom: 5px;
      }

    }

    &__work-time-hour {
      display: flex;
      align-items: center;

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000;
        border-radius: 50px;
        width: max-content;
        margin-right: 11px;
        height: 23px;

        &:last-child {
          margin-right: 0;
        }

        span {
          font-size: 12px;
          letter-spacing: -0.03em;
          color: #F8F8F8;
          background: #494949;
          border-radius: 50px;
          font-family: "Geometria", serif;
          font-weight: 400;
          padding: 6px;
          height: 23px;
          line-height: normal;
          text-transform: uppercase;
        }

        p {
          margin: 0;
          padding: 6px;
          font-size: 14px;
          letter-spacing: -0.01em;
          color: #F8F8F8;
          line-height: 12px;
          height: 23px;
          font-family: "Geometria", serif;
          font-weight: 400;
        }
      }
    }


    &__work-time-wrapper {
      display: flex;
      align-items: center;
    }

    &__phone-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__middle {
      width: 934px;
      margin: 0 auto 40px 0;

      p {
        font-size: 12px;
        letter-spacing: -0.03em;
        color: #727272;
        padding-bottom: 10px;
        border-bottom: 1px solid #494949;
        margin-bottom: 22px;
        line-height: 120%;

        svg {
          display: none;
        }
      }
    }

    &__phone-number {
      width: 25%;
    }

    &__phone-number {
      .footer__phone-title {
        display: flex;
        align-items: center;
        font-family: "Geometria", serif;
        font-weight: 400;
        color: #9C9C9C;
        letter-spacing: -0.01em;
        font-size: 16px;
        margin-bottom: 7px;

        svg {
          margin-right: 2px;
        }
      }
    }

    &__phone-number.footer__city-number {
      .footer__phone-title {
        font-size: 14px;
      }
    }


    &__phone-number-link {
      display: flex;
      flex-direction: column;

      a {
        font-family: "Geometria", serif;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__city-number-wrapper {
      flex-wrap: wrap;
      margin-bottom: 25px;
    }

    &__city-number {
      width: 25%;
      margin-bottom: 15px;
    }

    &__bottom {
      p {
        font-size: 12px;
        letter-spacing: -0.03em;
        color: #727272;
        margin-bottom: 5px;
      }
    }

    &__address {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: "Geometria", serif;
      font-weight: 400;
      letter-spacing: -0.01em;
      color: #FFFFFF;

      svg {
        margin-bottom: 2px;
        margin-right: 2px;
      }
    }

    &__copyrighting {
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #000;
      padding: 15px 0 15px;
    }

    &__about {
      font-family: "Geometria", serif;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: -0.01em;
      color: #9C9C9C;
      margin-bottom: 3px;
    }
  }

  .list-inline {
    margin: 0;
  }
}

@media screen and (max-width: 1230px) {
  .header-middle .header-middle-child .logo {
    min-width: 130px !important;
  }
  .header-middle .header-middle-child .logo .text-item {
    display: none;
    min-width: 130px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .search-relinking {
    left: 300px;
  }
}

@media only screen and (max-width: 1050px) {
  .cart-wrap {
    min-width: unset;
  }

  .header-middle-child .logo .text-item {
    display: none !important;
  }
  .catalog__button {
    display: none;
  }
  .bucket {
    display: none;
  }
  .header__language {
    display: none;
  }
  .header-middle .header-middle-child .logo {
    width: 40%;
  }
  .header-middle .header-middle-child .callback {
    a {
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.01em;
    }

    span {
      font-size: 12px;
      line-height: 110%;
      letter-spacing: -0.03em;
    }
  }

  .header {
    &__mobile-burger {
      display: block;
      z-index: 102;
      //width: 7.5%;
      width: 6%;
      margin-right: 10px;
    }

    &__mobile-burger-icon {
      display: flex;
      align-items: center;
      justify-content: left;
      height: 66px;
      border-right: 1px solid #EFEFEF;
      padding-right: 0;
    }

    &__wrapper {
      height: 66px;
      justify-content: space-between;
    }


    &__mobile-menu-backdrop.back-drop {
      background: rgba(0, 0, 0, 0.50);
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 101;
    }

    &__mobile-menu.open-menu {
      display: block;
      margin-left: -100px;

      &::-webkit-scrollbar {
        width: 3px;
        background-color: rgba(255, 0, 0, 0);
        height: 3px;
        border-radius: 50px;
        margin-left: 5px;
      }

      &::-webkit-scrollbar-thumb {
        margin-top: 5px;
        background-color: #b7b7b7;
        border-radius: 15px;
      }

    }

    &__mobile-menu {
      display: none;
      width: 280px;
      height: 100vh;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 101;
      padding: 13px 10px 20px 10px;
    }

    &__mobile-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    &__mobile-icon-close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      background: #F8F8F8;
      border-radius: 6px;
    }

    &__language-mobile {
      width: unset;
      display: flex;
      align-items: center;

      span {
        margin: 0 12px 0 12px;
      }
    }

    &__language-mobile {
      font-size: 12px;
      font-family: "Geometria", serif !important;
      font-weight: 500;
      border: unset;
      background: unset;
      padding: 0;
      color: #9C9C9C;
      text-transform: uppercase;

    }

    &__language-mobile.active {
      color: #1F1F1F;
    }

    &__mobile-menu-links {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid rgba(0, 0, 0, 0.14);
      padding: 0 14px;

      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #1F1F1F;
        font-family: "Geometria", serif;
        font-weight: 400;
        padding-top: 9px;
        padding-bottom: 9px;

        &:nth-child(2) {
          padding-top: 18px;
        }

        &:last-child {
          padding-bottom: 18px;
        }
      }

      svg {
        margin-right: 8px;
      }
    }

    &__mobile-first-link {
      border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    }

    &__mobile-description-links {
      display: flex;
      flex-direction: column;
      padding: 0 14px;

      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #1F1F1F;
        font-family: "Geometria", serif;
        font-weight: 400;
        padding-top: 9px;
        padding-bottom: 9px;

        &:first-child {
          padding-top: 18px;
        }
      }
    }

    &__call-back {
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.14);
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
    }
  }

  .callback__phone-wrapper.callback__more-phone-mobile-wrapper a {
    display: flex;
    align-items: center;
    font-family: "Geometria", serif;
    font-weight: 500;
    color: #000000;
    font-size: 16px;
  }

  .callback__phone-mobile {
    display: flex;
    flex-direction: column;

    a {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.01em;
      margin-bottom: 3px;
      color: #1F1F1F;
    }

    span {
      font-family: "Geometria", serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 110%;
      letter-spacing: -0.03em;
      color: #494949;
    }
  }
  .search-holder .search-by {
    padding: 0 12px;

    span {
      display: none;
    }

    svg {
      display: block;
    }
  }
  .search-result {
    border: 1px solid #727272;
    border-radius: 8px;
    left: 0;
    width: 100%;
    background: #fff;
    top: 40px !important;


    .inner-search {
      border-bottom: 1px solid #727272;
      padding: 8px 10px;
      cursor: pointer;

      a {
        color: #494949;
      }
    }
  }
  .catalog__list-aditional-description {
    margin-left: -100px;
    border-radius: unset;
    border-left: unset;
    padding: 15px 20px 0 20px;

    &.open {
      display: block;
      position: absolute;
      top: 55px;
      left: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }

  .open-menu {
    -webkit-animation: open-menu 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: open-menu 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes open-menu {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  }
  @keyframes open-menu {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  }

  .header-top-tablet {
    padding-left: 7px;
    padding-right: 10px;
  }
  .header-bottom .menu {
    display: none;
  }
  .catalog {
    padding-left: 10px;
    padding-right: 10px;
  }
  .header-delivery {
    padding-left: 0;
    padding-right: 0;
    overflow-x: auto;

    &__text {
      min-width: 35%;
    }
  }
  .header-middle .header-middle-child .logo {
    min-height: 66px;
  }
  .header-middle .header-middle-child .callback {
    min-height: 66px;
    height: 66px;
  }
  .callback__drop-down-phone {
    //justify-content: flex-end;
    height: 66px;
    margin-right: 0;
    padding-left: 52px;
  }
  .callback__more-phone {
    left: unset;
    transform: unset;
    right: 0;
    top: 100%;
  }
  .cart-count-text {
    display: none;
  }
  .search-icon {
    display: none;
  }
  .search-holder .form-control {
    padding: 1px 175px 1px 20px;
  }
  .search-holder {
    width: 100%;
    margin-right: 25px;
  }
  .search-relinking {
    left: 40px;
  }
  .cart-wrap {
    //width: 30%;
  }
  .checkout-wrap.active {
    width: 100%;
  }
  .catalog-button-btn-mobile.active {
    background: linear-gradient(0deg, #338C52, #338C52), linear-gradient(0deg, #3A4DB1, #3A4DB1), #401CA8;
  }

  .catalog__button-mobile {
    //position: relative;
    margin-bottom: 15px;

    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      border: none;
      background: linear-gradient(0deg, #44BB6E, #44BB6E), linear-gradient(0deg, #4E67ED, #4E67ED), #5625E1;
      border-radius: 8px;
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 14px;
      height: 45px;
      padding: 10px 10px;
      letter-spacing: 0.01em;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      svg {
        margin-right: 8px;
      }
    }
  }
  .catalog__mobile.open-mobil-catalog {
    display: block;
    width: 100%;
    height: 100vh;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -100px;
    z-index: 1;
  }
  .catalog__arrow-links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 46px;

    svg {
      margin-right: 10px;
    }
  }
  .catalog {
    &__mobile {
      display: none;
    }

    &__mobile-head {
      display: flex;
      align-items: center;
      height: 55px;
      background: #EFEFEF;
      border-radius: 0 0 12px 12px;
      padding: 0 20px;
      overflow: scroll;

      &::-webkit-scrollbar {
        width: 3px;
        background-color: rgba(255, 0, 0, 0);
        height: 3px;
        border-radius: 50px;
        margin-left: 5px;
      }

      &::-webkit-scrollbar-thumb {
        margin-top: 5px;
        background-color: #b7b7b7;
        border-radius: 15px;
      }
    }

    &__mobile-back {
      width: max-content;
      display: flex;
      align-items: center;
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: -0.03em;
      color: #1F1F1F;
      padding: 7px 10px;
      background: #FFFFFF;
      border-radius: 8px;
      white-space: nowrap;

      &:first-child {
        margin-right: 5px;
      }

      svg {
        margin-right: 6px;
      }
    }

    &__list-mobile {

    }

    &__list-mobile-description .catalog__list-mobile-show-all {
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: #F8F8F8;
      border-radius: 6px;
      padding: 11px 15px;
      text-align: center;

      a {
        display: block;
        text-align: center;
        letter-spacing: 0.01em;
        font-size: 14px;
        font-family: "Geometria", serif;
        font-weight: 500;
        color: #1F1F1F;
        padding: 0;
      }
    }

    &__list-mobile-description {
      display: none;
      width: 100%;
      height: 100vh;
      background: #fff;
      position: absolute;
      top: 55px;
      left: 0;
      z-index: 1;
    }

    &__list-mobile-description.open-sub-catalog {
      display: block;
      margin-left: -100px;
      overflow: hidden;
      height: 500px;
    }

    &__list-aditional-links-mobile {
      padding: 15px 20px 0 20px;
      height: 450px;
      overflow: scroll;

      &::-webkit-scrollbar {
        width: 3px;
        background-color: rgba(255, 0, 0, 0);
        height: 3px;
        border-radius: 50px;
        margin-left: 5px;
      }

      &::-webkit-scrollbar-thumb {
        margin-top: 5px;
        background-color: #b7b7b7;
        border-radius: 15px;
      }
    }

    &__subcategory {
      display: flex;
      align-items: center;

      a {
        padding: 9px 0 9px 0;
        font-size: 14px;
      }

      p {
        font-size: 14px;
        letter-spacing: -0.01em;
        font-family: "Geometria", serif;
        font-weight: 300;
      }

      svg {
        position: absolute;
        right: 20px;
      }
    }

    &__list-holder-mobile {
      p {
        display: flex;
        align-items: center;
        width: 80%;
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.01em;
        font-family: "Geometria", serif;
        font-weight: 400;
        @media screen and(max-width: 768px) {
          width: 100%;
        }

        a {
          width: 20%;
        }

        img {
          margin-right: 8px;
        }

        svg {
          position: absolute;
          right: 20px;
        }
      }
    }

    &__list-holder {
      //position: relative;
      padding: 0 0 0 20px;
    }
  }

  .open-mobil-catalog {
    -webkit-animation: open-mobil-catalog 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: open-mobil-catalog 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes open-mobil-catalog {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  }
  @keyframes open-mobil-catalog {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  }

  .open-sub-catalog {
    -webkit-animation: open-mobil-catalog 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: open-mobil-catalog 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes open-mobil-catalog {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  }
  @keyframes open-mobil-catalog {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
  }

  footer {
    position: relative;
    top: 0vh;
    bottom: 0vh;
    width: 100%;
    height: auto;

    .footer {
      &-holder {
        padding: 0;

        &__wrapper {
          padding: 0;
        }
      }

      &__wrapper {
        flex-direction: column;
        padding-left: 0;
      }

      &__left {
        order: 2;
        width: 100%;
        padding: 50px 20px 40px;
      }

      &__right {
        width: 100%;
        order: 1;
        padding: 45px 20px 50px 20px;
        margin-top: 0;
      }

      &__top {
        width: 100%;
      }

      &__general-phone {
        svg {
          margin-right: 14px;
        }

        a {
          font-size: 32px;
        }

      }


      &__middle {
        width: 100%;
        border-bottom: none;
        margin: 0 auto 25px 0;

        p {
          svg {
            display: none;
          }
        }
      }

      &__copyrighting {
        padding: 13px 20px;
        border-top: 1px solid #1F1F1F;
      }

      &__about {
        width: 250px;
        text-align: center;
      }
    }

    .footer-holder .menu-footer {
      margin: 0;
      flex-wrap: wrap;
      flex-direction: unset;
      justify-content: unset;
      align-items: unset;

      li {
        width: 33%;
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  .header {
    &__call-back {
      display: none;
    }

  }
  .header__wrapper {
    height: 55px;
    justify-content: space-between;
  }
  .header-middle .header-middle-child .logo .text-item {
    display: none;
  }
  .header-middle .header-middle-child .logo .logo__link {
    width: 57px;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: 10px;
  }
  .header-middle .header-middle-child .logo {
    position: unset;
    padding: 0;
    width: 57px;
    min-height: 66px;
    min-width: unset;
    margin-right: 0;
  }
  .search-holder {
    width: 55%;
    max-width: none !important;
  }
  .header-delivery {
    padding-left: 0;
    padding-right: 0;
    height: 65px;

    &__text {
      min-width: 50%;
      margin-right: 10px;
      font-size: 10px;
      svg {
        width: 30px;
      }
    }
  }
  .callback__mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #EFEFEF;
    border-right: 1px solid #EFEFEF;
    width: 57px;
    min-height: 59px;
    height: 55px;
  }
  .callback__drop-down-phone {
    height: 55px;
    margin-right: 0;
    min-width: unset;
  }

  .callback__phone-mobile {
    display: flex;
    flex-direction: column;

    a {
      font-size: 20px;
      color: #1F1F1F;
      font-family: "Geometria", serif;
      font-weight: 500;
      margin-bottom: 3px;
    }

    span {
      font-size: 12px;
      font-family: "Geometria", serif;
      font-weight: 500;
      color: #494949;
    }
  }

  .callback-desktop-icon {
    display: none;
  }
  .callback-mobile-icon {
    display: block;
  }
  .callback__phone {
    display: none;
  }
  .callback__more-phone {
    width: 257px;
    left: unset;
    transform: unset;
    right: 0;
    top: 55px;

    &.active {
      display: block;
    }
  }
  .header-middle .header-middle-child .callback {
    width: 57px;
    min-height: 59px;
    height: 55px;
    display: none;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #EFEFEF;
    border-right: 1px solid #EFEFEF;
    position: relative;
    min-width: unset;
    max-width: unset;
  }
  .cart-wrap {
    display: none;
  }
  .catalog {
    min-height: 50px;
  }
  .header-bottom .container-wrapper {
    padding: 0;
  }
  .search-holder {
    width: 100%;
    height: auto;
    margin-right: 0;
    border-radius: 0;
  }
  .search-holder .form-control {
    height: 50px;
    border-radius: 0;
    padding: 1px 57px 1px 18px;
    font-family: "Geometria", serif;
    font-weight: 500;
    font-size: 14px;
    &::placeholder {
      font-family: "Geometria", serif;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .search-holder .search-by {
    border-radius: 0;
    padding: 17px;

    span {
      display: none;
    }

    svg {
      display: block;
    }
  }

  .mobile-cart {
    display: block;
  }

  .search-result {
    top: 50px !important;
    border-radius: unset;

    .inner-search {
      a {
        color: #494949;
        display: flex;
      }
    }
  }

  footer {

    padding-bottom: 0;

    .footer {
      &__right {
        padding: 27px 20px 0 20px;
      }

      &__top {
        padding-bottom: 25px;
        flex-wrap: wrap;
        border-bottom: 1px solid #494949;
        margin-bottom: 25px;
      }

      &__middle {
        width: 100%;
        border-bottom: 1px solid #494949;
        margin: 0 auto 25px 0;

        p {
          svg {
            display: block;
          }
        }
      }

      &__general-phone {
        margin-bottom: 15px;

        a {
          font-size: 22px;
          line-height: 120%;
          letter-spacing: -0.01em;
        }

        svg {
          margin-right: 10px;
        }
      }

      &__work-time-wrapper {
        flex-wrap: wrap;
      }

      &__work-time-hour {
        flex-wrap: wrap;

        &:nth-child(-n+2) {
          //margin-bottom: 9px;
        }
      }

      &__work-time-hour-wrapper {
        margin-right: 7px;
        margin-bottom: 9px;
      }

      &__middle {
        p {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          border-bottom: none;
          font-size: 16px;
          margin-bottom: 25px;
          padding: 0;
          font-family: "Geometria", serif;
          font-weight: 500;
          color: #EFEFEF;
        }
      }

      &__arrow-not-active {
        transition: 0.25s;

        svg {
          transform: rotate(180deg);
          transition: 0.25s;
        }

      }

      &__arrow-not-active.rotate {
        transition: 0.25s;

        svg {
          transform: rotate(0deg);
          transition: 0.25s;
        }

      }

      &__phone-wrapper {
        flex-wrap: wrap;
      }

      &__phone-number {
        width: 100%;
        margin-bottom: 17px;
      }

      &__phone-wrapper {
        //display: block;
        opacity: 1;
        height: auto;
        margin-bottom: 25px;
        transition: 0.25s;
      }

      &__phone-wrapper.active {
        //display: none;
        opacity: 0;
        height: 0;
        margin-bottom: 0;
        transition: 0.25s;
      }

      &__phone-title {
        font-size: 16px;
        margin-bottom: 12px;
      }

      &__phone-number-link {
        a {
          margin-bottom: 10px;
        }
      }

      &__bottom {
        border-bottom: 1px solid #494949;
        padding-bottom: 25px;

        p {
          font-size: 16px;
          margin-bottom: 10px;
          font-family: "Geometria", serif;
          font-weight: 500;
          color: #EFEFEF;
        }
      }

      &-holder .menu-footer {
        flex-direction: column;

        li {
          width: 100%;
          margin-bottom: 10px;

          &:nth-last-child(2) {
            padding-top: 15px;
          }
        }

        a {
          font-size: 14px;
        }
      }

      .list-inline {
        padding: 0;
      }

      &__left {
        order: 2;
        width: 100%;
        padding: 25px 20px;
        background-color: #1F1F1F;
      }

      &__copyrighting {
        padding: 25px 20px;
        height: 100%;
        flex-direction: column;
      }

      &__about {
        margin-bottom: 13px;
      }

      &__social-link {
        order: 2;
        margin-bottom: 13px;
      }

      &__payment {
        order: 3;
      }

      &-holder {
        &__wrapper {
          .menu-footer {
            li {
              a {
                font-size: 14px;
                line-height: 120%;
                letter-spacing: -0.01em;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
  .header__right-wrapper {
    display: flex;
    align-items: center;
    //width: 34.1%;
    height: 100%;
    justify-content: center;
    position: static;
  }
  .header__basket-mobile {
    display: block;

    button {
      position: relative;
      background: none;
      border: none;
      outline: unset;

      svg {
        fill: #1F1F1F;
      }
    }
  }
  .cart-count-dot {
    position: absolute;
    top: 6px;
    right: 11px;
    left: unset;
  }

  .header__basket-mobile {
    width: 57px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .header-top-tablet {
    &.container-wrapper-new {
      //padding-left: 0;
      padding-right: 0;
    }
  }
  .header__mobile-burger {
    width: 55px;
    border-right: 1px solid #EFEFEF;
    margin-right: 17px;
  }
  .header__mobile-burger-icon {
    height: 53px;
    padding: 0;
  }

  .header__basket-full.modal-u__body {
    position: absolute;
    top: 38px;
    width: unset;
  }
  .header__basket-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
  }

}


@media (hover: hover) {
  .callback:hover .callback__more-phone {
    display: block;
    transition: 0.25s;
  }
  .callback:hover {
    box-shadow: 0px -9px 20px rgba(0, 0, 0, 0.1), 0px 10px 35px rgba(0, 0, 0, 0.15);
    border-radius: 8px 8px 0 0;
    //transition: 0.25s;
  }
  .callback__drop-down-phone:hover .callback__phone svg {
    transform: rotate(180deg);
    transition: 0.25s;
  }
}

@media screen and (width: 1920px) {
  .header__right-wrapper {
    margin-left: 197px;
  }
}
