// NEW SEARCH STYLE

.search{
  display: flex;
  justify-content: center;
  margin: 0 auto 30px auto;
  width: 50%;
  &-page__title {
    padding-top: 20px;
  }
  &-page__wrapper {
    position: relative;
    background-image: url(/assets/images/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__text-title {
    margin: 20px 0 10px 0;
  }
  &__text-sub-title{
    font: 22px/30px 'Geometria', sans-serif;
    font-weight: 300;
    margin-bottom: 20px;
  }
  &__popular-category {
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button  {
        display: block;
        width: 44px;
        height: 44px;
        background: #F8F8F8;
        border-radius: 8px;
        border: 0;
        margin-right: 5px;
      }
    }
  }

}

#search__popular-category {
  .slick-slide {
       padding: 10px;
    img{
      margin: 0 auto 20px auto;
      width: 100%;
      height: 260px;
      border-radius: 10px;
      object-fit: contain;
    }
    p{
      font-family: "Geometria",serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.01em;
      color: #1F1F1F;
      margin-bottom: 0;
    }
    span{
      font-family: "Geometria",serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: #9C9C9C;
    }
  }

}

.main .slick-prev, .main .slick-prev:focus, .main .slick-prev:hover{
  left: 0;
}
.main .slick-next, .main .slick-next:focus, .main .slick-next:hover {
  right: 0;
}
@media only screen and (max-width: 1000px) {
  .search {
    width: 100%;
    .search-holder {
      width: 100%;
      margin-right: 0;
    }
  }
  #search__popular {
    .item-category {
      width: 240px;
      padding: 10px 15px;
      .image-holder {
        &.image-size {
          width: 216px;
          height: 216px;
          margin-bottom: 8px;
        }
      }
      .action {
        top: 16px;
        left: 18px;
      }
    }
    .new-items__buy-button {
      right: 17px;
      top: 16px;
    }
    .popular__button-mobile {
      width: 44px;
      border-radius: 10px;
    }
    .popular__button-mobile-added {
      border: 2px solid #44BB6E;
      border-radius: 10px;
      padding: 10px;
    }
    .wrap-slider-rec-products .category-links.slider-tovar .holder .box-category-holder .box-category .item-category .description-category .block-buy .btn-buy {
      border: 2px solid #44BB6E;
      border-radius: 10px;
      padding: 10px;
    }

    #search__popular-category-wrapper{
      &.container-wrapper-new {
        padding: 0 20px;
      }
    }
  }
  #search__popular-category {
    .slick-slide {
      img{
        width: 216px;
        height: 216px;
      }

    }
  }
}
@media only screen and (max-width: 767px) {
    #search__popular {
      .new-item__button-wrapper {
        p {
          font-size: 22px;
        }
      }
      .new-items__buttons {
        button {
          width: 35px;
          height: 35px;
        }
      }
      .item-category {
        width: 100%;
        padding: 10px;

        .image-holder {
          &.image-size {
            width: 120px;
            height: 120px;
          }
        }

        .action {
          top: 16px;
          left: 18px;
        }
      }
    }
  .wrap-slider-rec-products {
    .category-links {
      &.slider-tovar {
        .holder {
          .box-category-holder {
            .box-category {
              .item-category {
                .description-category {
                  .block-buy {
                    .btn-buy {
                      width: 36px;
                      height: 36px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #search__popular-category-wrapper {
    &.container-wrapper-new {
      padding: 0;
    }
    .new-item__button-wrapper {
      p {
        font-size: 22px;
        text-align: left;
      }
    }
    .search__popular-category__buttons {
      button {
        width: 35px;
        height: 35px;
      }
    }
  }
  #search__popular-category {
    .slick-slide {
      img {
        width: 120px;
        height: 120px;
      }
    }
  }

}


