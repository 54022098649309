// NEW CART STYLE
.basket-page {
  position: relative;
  background-image: url(/assets/images/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}


@media only screen and (max-width: 1000px) {

}
@media only screen and (max-width: 767px) {

}


